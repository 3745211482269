import BenefitYear from "src/models/BenefitYear";
import BenefitYearsApi from "src/api/BenefitYearsApi";
import { ErrorsLogic } from "./useErrorsLogic";
import dayjs from "dayjs";
import { useState } from "react";

interface CurrentAndNextBenefitYearReturn {
  currentBenefitYear: BenefitYear | null;
  nextBenefitYear: BenefitYear | null;
}

const useBenefitYearsLogic = ({
  errorsLogic,
}: {
  errorsLogic: ErrorsLogic;
}) => {
  const benefitYearApi = new BenefitYearsApi();

  const [isLoadingBenefitYears, setIsLoadingBenefitYears] = useState<boolean>();
  const [benefitYearsData, setBenefitYearsData] = useState<BenefitYear[]>();

  const hasLoadedBenefitYears = () => {
    return !!benefitYearsData;
  };

  const loadBenefitYears = async () => {
    if (isLoadingBenefitYears) return;
    const shouldBenefitYearsLoad = !benefitYearsData;
    if (shouldBenefitYearsLoad) {
      setIsLoadingBenefitYears(true);
      errorsLogic.clearErrors();
      try {
        const benefitYears = await benefitYearApi.getBenefitYears();
        setBenefitYearsData(benefitYears);
      } catch (error) {
        errorsLogic.catchError(error);
        setBenefitYearsData([]);
      } finally {
        setIsLoadingBenefitYears(false);
      }
    }
  };

  const loadBenefitYearsByClaim = async (absenceId: string) => {
    if (isLoadingBenefitYears) return;
    const shouldBenefitYearsLoad = !benefitYearsData;
    if (shouldBenefitYearsLoad) {
      setIsLoadingBenefitYears(true);
      errorsLogic.clearErrors();
      try {
        const benefitYears = await benefitYearApi.getBenefitYearsByClaim(
          absenceId
        );
        setBenefitYearsData(benefitYears);
      } catch (error) {
        errorsLogic.catchError(error);
        setBenefitYearsData([]);
      } finally {
        setIsLoadingBenefitYears(false);
      }
    }
  };

  const allBenefitYearsHaveSameEmployeeId = () =>
    new Set(benefitYearsData?.map((by) => by.employee_id)).size === 1;

  const getCurrentBenefitYear = () => {
    if (!hasLoadedBenefitYears()) return null;
    if (!allBenefitYearsHaveSameEmployeeId()) return null;
    return benefitYearsData?.find((by) => by.current_benefit_year);
  };

  const getNextBenefitYearByDate = (comparisonDate: string) => {
    if (!comparisonDate) return null;
    if (!benefitYearsData) return null;
    if (!allBenefitYearsHaveSameEmployeeId()) return null;

    const sortedBenefitYears = sortBenefitYears(benefitYearsData);
    const currentBenefitYearIndex = sortedBenefitYears.findIndex((a) =>
      dayjs(comparisonDate).isBefore(dayjs(a.benefit_year_end_date))
    );

    return sortedBenefitYears[currentBenefitYearIndex + 1];
  };

  const getCurrentAndNextBenefitYear = (): CurrentAndNextBenefitYearReturn => {
    if (benefitYearsData) {
      const sortedBenefitYears = sortBenefitYears(benefitYearsData);

      const currentBenefitYearIndex = sortedBenefitYears.findIndex(
        (by) => by.current_benefit_year
      );

      const currentBenefitYear =
        sortedBenefitYears[currentBenefitYearIndex] ?? null;
      const nextBenefitYear =
        benefitYearsData.length > 1
          ? sortedBenefitYears[currentBenefitYearIndex + 1]
          : null;

      return {
        currentBenefitYear,
        nextBenefitYear,
      };
    }
    return {
      currentBenefitYear: null,
      nextBenefitYear: null,
    };
  };

  const getLatestBenefitYear = (): BenefitYear | null => {
    if (benefitYearsData) {
      const sortedBenefitYears = sortBenefitYears(benefitYearsData);
      return sortedBenefitYears[sortedBenefitYears.length - 1];
    }
    return null;
  };

  const sortBenefitYears = (benefitYears: BenefitYear[]) =>
    [...benefitYears].sort((a, b) =>
      a.benefit_year_start_date.localeCompare(b.benefit_year_start_date)
    );

  const getEmployeeBenefitYears = (employeeId: string) =>
    <BenefitYear[]>(
      benefitYearsData?.filter((by) => by.employee_id === employeeId)
    );

  const areBenefitYearsValid = (benefitYears: BenefitYear[]) => {
    if (benefitYears.length === 0) return true;

    // if there is a non-null Date value, Boolean will be true
    return Boolean(
      benefitYears.every((by) => by.invalid_benefit_years_since === null)
    );
  };

  // returns true if user has any invalid benefit years
  const userHasInvalidBenefitYear = () => {
    if (!hasLoadedBenefitYears() || !benefitYearsData) return false;
    return !areBenefitYearsValid(benefitYearsData);
  };

  const employeeHasInvalidBenefitYear = (employeeId: string) => {
    if (!hasLoadedBenefitYears() || !employeeId) return false;

    // first filter to only this employee's benefit years
    const employeeBenefitYears = getEmployeeBenefitYears(employeeId);
    return !areBenefitYearsValid(employeeBenefitYears);
  };

  return {
    loadBenefitYears,
    loadBenefitYearsByClaim,
    benefitYearsData,
    getCurrentAndNextBenefitYear,
    getLatestBenefitYear,
    hasLoadedBenefitYears,
    getCurrentBenefitYear,
    getNextBenefitYearByDate,
    userHasInvalidBenefitYear,
    allBenefitYearsHaveSameEmployeeId,
    employeeHasInvalidBenefitYear,
  };
};

export default useBenefitYearsLogic;
export type BenefitYearsLogic = ReturnType<typeof useBenefitYearsLogic>;
