import Language from "src/models/LanguageEnum";
import LocalizeLanguage from "src/models/LocalizeLanguageEnum";

// Transforms localize language code to system language string.
const transformLocalizeToSystemLanguage = (localizeCode: string) => {
  const languageKey = (
    Object.keys(LocalizeLanguage) as Array<keyof typeof LocalizeLanguage>
  ).find((key) => LocalizeLanguage[key] === localizeCode);
  if (languageKey) {
    return Language[languageKey];
  }
};

export default transformLocalizeToSystemLanguage;
