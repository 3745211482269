/**
 * @file Defines custom i18n formatters which apply special formatting rules to specific
 * types of content strings. For example, currency values are formatted into a dollar amount
 * with dollar sign, a thousands separator comma, and cent values. Formatters can be internationalized
 * by using the locale value to change formatting behavior.
 * @see https://www.i18next.com/translation-function/formatting
 */
import convertMinutesToHours from "../utils/convertMinutesToHours";
import formatDate from "../utils/formatDate"; // TODO(PFMLPB-11768) Move formatDate module out of utils folder to current module directory

/**
 * Add additional formatting to a given translation string
 * @see https://www.i18next.com/translation-function/formatting
 */
export default function formatValue(
  value: string,
  format:
    | "currency"
    | "date"
    | "dateFull"
    | "ein"
    | "hoursMinutesDuration"
    | "raw",
  locale: string
) {
  // Don't format undefined values
  // If we format undefined values, they wouldn't be treated as missing values
  // and the missingInterpolationHandler wouldn't be called
  if (value === undefined) return value;

  // 'raw' returns the value without <var> tags so that the translator will
  // handle the value.
  // EX: greeting: "{{greetingType}}, {{name}}" will translate to
  // <var>hello</var>, <var>Joe</var>
  // greeting: "{{greetingType, raw}}, {{name}}" will translate to
  // hello, <var>Joe</var>
  // the translator will now translate "hello,"
  if (format === "raw") return value;

  let formatted = value;
  if (format === "currency") {
    formatted = formatCurrency(value, locale);
  } else if (format === "date") {
    formatted = formatDate(value).short();
  } else if (format === "dateFull") {
    formatted = formatDate(value).full();
  } else if (format === "ein") {
    formatted = formatEmployerFein(value);
  } else if (format === "hoursMinutesDuration") {
    formatted = formatHoursMinutesDuration(value, locale);
  }
  return addVarTag(formatted);
}

/**
 * Formats number into currency. For example:
 *   1000 -> $1,000.00
 */
function formatCurrency(value: string, locale: string) {
  const number = Number(value);
  if (isNaN(number)) return value;

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
  }).format(number);
}

/**
 * Formats an EIN returned from the server to utilize a non-breaking hyphen
 * so that the entire EIN is always displayed on the same line if possible.
 */
function formatEmployerFein(value: string) {
  return value.replace("-", "‑");
}

/**
 * Formats a duration into hours and minutes. For example:
 *   480 -> 8h
 *   475 -> 7h 55m
 */
function formatHoursMinutesDuration(value: string, _locale: string) {
  // Add any internationalizations here. For example:
  // if (locale === 'en-US') {
  //   return `${value} minutes`
  // }

  // The default return value will be the English-idiomatic "h" and "m" abbreviations for
  // hours and minutes
  const { hours, minutes } = convertMinutesToHours(Number(value));
  if (minutes === 0) return `${hours}h`;
  return `${hours}h ${minutes}m`;
}

function addVarTag(value: string) {
  // Need to explicitly check for null since we still want to render
  // values like 0
  // Note also that value should never be undefined since we check for that
  // in the formatValue function
  if (value === undefined || value === null) {
    return "<var />";
  }
  return `<var>${value}</var>`;
}
