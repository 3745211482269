import withAppeal, { WithAppealProps } from "../../../hoc/withAppeal";

import Appeal from "../../../models/Appeal";
import Heading from "../../../components/core/Heading";
import QuestionPage from "../../../components/QuestionPage";
import React from "react";
import Textarea from "../../../components/core/Textarea";
import routeWithParams from "../../../utils/routeWithParams";
import useFormState from "../../../hooks/useFormState";
import useFunctionalInputProps from "../../../hooks/useFunctionalInputProps";
import { useTranslation } from "react-i18next";

export const fields: Array<keyof Appeal> = ["appeal_reason"];

export const Reason = (props: WithAppealProps) => {
  const { appLogic, appeal } = props;
  const { t } = useTranslation();

  const { formState, updateFields } = useFormState(appeal);
  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const handleSave = async () =>
    await appLogic.appeals.update(appeal.appeal_id, formState);

  return (
    <QuestionPage
      onSave={handleSave}
      title={t("pages.appealsReason.title")}
      backButtonLabel={t("pages.appealsReason.backButtonLabel")}
      backButtonLink={routeWithParams("applications.status.claim", {
        absence_id: appeal.fineos_absence_id,
      })}
    >
      <Heading level="2" size="1">
        {t("pages.appealsReason.header")}
      </Heading>
      <Textarea
        {...getFunctionalInputProps("appeal_reason")}
        label={t("pages.appealsReason.reasonLabel")}
        smallLabel
        optionalText={t("components.form.optional")}
      />
    </QuestionPage>
  );
};

export default withAppeal(Reason);
