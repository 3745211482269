import { get, pick, set } from "lodash";
import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "../../hoc/withBenefitsApplication";

import Alert from "../../components/core/Alert";
import ConditionalContent from "../../components/ConditionalContent";
import Details from "../../components/core/Details";
import ExternalLink from "src/components/core/ExternalLink";
import InputChoiceGroup from "../../components/core/InputChoiceGroup";
import LeaveReasonEnum from "../../models/LeaveReason";
import QuestionPage from "../../components/QuestionPage";
import React from "react";
import { ReasonQualifier as ReasonQualifierEnum } from "../../models/BenefitsApplication";
import { Trans } from "react-i18next";
import routes from "../../routes";
import useFormState from "../../hooks/useFormState";
import useFunctionalInputProps from "../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../locales/i18n";

export const fields = [
  "claim.leave_details.reason",
  "claim.leave_details.reason_qualifier",
];

export const LeaveReason = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const { formState, getField, updateFields, clearField } = useFormState(
    pick(props, fields).claim
  );
  const reason = get(formState, "leave_details.reason");
  const reason_qualifier = get(formState, "leave_details.reason_qualifier");

  const handleSave = async () => {
    // when the claimant changes the answer, clear out pregnant_or_recent_birth field
    if (reason !== get(claim, "leave_details.reason")) {
      set(formState, "leave_details.pregnant_or_recent_birth", null);
    }

    // Update leave_details
    if (reason === LeaveReasonEnum.medical) {
      set(formState, "leave_details.reason_qualifier", null);
      set(formState, "leave_details.pregnant_or_recent_birth", false);
    } else if (reason === LeaveReasonEnum.pregnancy) {
      set(formState, "leave_details.reason_qualifier", null);
      set(formState, "leave_details.pregnant_or_recent_birth", true);
    }

    if (reason !== LeaveReasonEnum.bonding) {
      set(formState, "leave_details.child_birth_date", null);
      set(formState, "leave_details.child_placement_date", null);
      set(formState, "leave_details.has_future_child_date", null);
    }

    await appLogic.benefitsApplications.update(claim.application_id, formState);
  };

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const choiceMedical = {
    checked: reason === LeaveReasonEnum.medical,
    label: t("pages.claimsLeaveReason.medicalLeaveDueToIllnessInjuryLabel"),
    value: LeaveReasonEnum.medical,
  };

  const choicePregnancy = {
    checked: reason === LeaveReasonEnum.pregnancy,
    label: <Trans i18nKey={"pages.claimsLeaveReason.pregnancyLabel"} />,
    value: LeaveReasonEnum.pregnancy,
    hint: <Trans i18nKey={"pages.claimsLeaveReason.pregnancyLeaveHint"} />,
  };

  const choiceBonding = {
    checked: reason === LeaveReasonEnum.bonding,
    label: t("pages.claimsLeaveReason.bondingLeaveLabel"),
    value: LeaveReasonEnum.bonding,
  };

  const choiceCaringLeave = {
    checked: reason === LeaveReasonEnum.care,
    label: t("pages.claimsLeaveReason.caringLeaveLabel"),
    value: LeaveReasonEnum.care,
  };

  const getChoices = () => {
    return [choiceMedical, choicePregnancy, choiceBonding, choiceCaringLeave];
  };

  return (
    <QuestionPage
      title={t("pages.claimsLeaveReason.title")}
      onSave={handleSave}
    >
      <Alert state="info" neutral>
        <Trans
          i18nKey="pages.claimsLeaveReason.alertBody"
          components={{
            "contact-center-phone-link": (
              <a href={`tel:${t("shared.contactCenterPhoneNumber")}`} />
            ),
            "leave-types-link": (
              <a href={routes.external.massgov.leaveTypeDecisionTree} />
            ),
            p: <p />,
            ul: <ul className="usa-list" />,
            li: <li />,
          }}
        />
      </Alert>

      <InputChoiceGroup
        {...getFunctionalInputProps("leave_details.reason")}
        choices={getChoices()}
        label={t("pages.claimsLeaveReason.sectionLabel")}
        type="radio"
        hint={
          <Details label={t("pages.claimsLeaveReason.sectionLearnMoreLabel")}>
            <Trans
              i18nKey={"pages.claimsLeaveReason.sectionLearnMoreDetail"}
              components={{
                b: <b />,
                br: <br />,
                i: <i />,
              }}
            />
          </Details>
        }
      />

      <ConditionalContent
        fieldNamesClearedWhenHidden={["leave_details.reason_qualifier"]}
        getField={getField}
        clearField={clearField}
        updateFields={updateFields}
        visible={reason === LeaveReasonEnum.bonding}
      >
        <InputChoiceGroup
          {...getFunctionalInputProps("leave_details.reason_qualifier")}
          hint={
            <React.Fragment>
              <Trans i18nKey="pages.claimsLeaveReason.bondingTypeDetailsSummary" />

              <Details
                label={t(
                  "pages.claimsLeaveReason.bondingTypeFamilyLeaveDetailsLabel"
                )}
              >
                <Trans
                  i18nKey="pages.claimsLeaveReason.bondingTypeFamilyLeaveDetailsSummary"
                  components={{
                    "multiple-births-link": (
                      <ExternalLink
                        href={routes.external.massgov.multipleBirths}
                      />
                    ),
                  }}
                />
              </Details>

              <Details
                label={t(
                  "pages.claimsLeaveReason.bondingTypeMultipleBirthsDetailsLabel"
                )}
              >
                <Trans
                  i18nKey="pages.claimsLeaveReason.bondingTypeMultipleBirthsDetailsSummary"
                  components={{
                    "multiple-births-link": (
                      <ExternalLink
                        href={routes.external.massgov.multipleBirths}
                      />
                    ),
                  }}
                />
              </Details>
            </React.Fragment>
          }
          choices={[
            {
              checked: reason_qualifier === ReasonQualifierEnum.newBorn,
              label: t("pages.claimsLeaveReason.bondingTypeNewbornLabel"),
              value: ReasonQualifierEnum.newBorn,
            },
            {
              checked: reason_qualifier === ReasonQualifierEnum.adoption,
              label: t("pages.claimsLeaveReason.bondingTypeAdoptionLabel"),
              value: ReasonQualifierEnum.adoption,
            },
            {
              checked: reason_qualifier === ReasonQualifierEnum.fosterCare,
              label: t("pages.claimsLeaveReason.bondingTypeFosterLabel"),
              value: ReasonQualifierEnum.fosterCare,
            },
          ]}
          label={t("pages.claimsLeaveReason.bondingTypeLabel")}
          type="radio"
        />
      </ConditionalContent>
    </QuestionPage>
  );
};

export default withBenefitsApplication(LeaveReason);
