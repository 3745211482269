import React from "react";
import { Trans } from "react-i18next";

interface PaginationSummaryProps {
  pageOffset: number;
  pageSize: number;
  totalRecords: number;
}

const PaginationSummary = (props: PaginationSummaryProps) => {
  const { pageOffset, pageSize, totalRecords } = props;

  const firstRecordNumber = pageSize * (pageOffset - 1) + 1;
  const maxRecordNumberForPage = firstRecordNumber + pageSize - 1;
  const lastRecordNumber =
    maxRecordNumberForPage > totalRecords
      ? totalRecords
      : maxRecordNumberForPage;

  return (
    <p
      className="line-height-sans-2"
      aria-live="polite"
      title="pagination summary"
      data-testid="pagination-summary"
    >
      <Trans
        i18nKey="components.pagination.summary"
        values={{
          context: totalRecords === 0 ? "empty" : undefined,
          firstRecordNumber: Number(firstRecordNumber).toLocaleString(),
          lastRecordNumber: Number(lastRecordNumber).toLocaleString(),
          totalRecords: Number(totalRecords).toLocaleString(),
        }}
      />
    </p>
  );
};

export default PaginationSummary;
