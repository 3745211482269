import ChangeRequest from "../models/ChangeRequest";
/**
 * Get only objects associated with a given Claim
 */
function filterByClaim(
  // update the type if intended to used by other models
  items: ChangeRequest[],
  fineos_absence_id: string
) {
  return items.filter((item) => {
    return item.fineos_absence_id === fineos_absence_id;
  });
}

export default filterByClaim;
