/**
 * @file Configuration for building an xstate state machine for routing
 * @see https://xstate.js.org/docs/about/concepts.html#finite-state-machines
 * Each state represents a page in the portal application, keyed by the
 * page's url route. The CONTINUE transition represents the next page in the
 * the application flow.
 *
 * This configuration is used to determine and group pages by
 * its step in the application process. The data provided in `meta`
 * is used to determine which step a page belongs to and whether that step
 * is complete, in progress, or not started
 * @see ../models/Step
 */

import BenefitYear, { nextBenefitYearData } from "src/models/BenefitYear";
import BenefitsApplication, {
  EmploymentStatus,
  WorkPatternType,
} from "../models/BenefitsApplication";
import User, {
  UserProfileCheckForUpdatesResponse,
  UserProfileHasUsableDataForApplicationResponse,
} from "src/models/User";
import { get, isEmpty } from "lodash";

import ChangeRequest from "../models/ChangeRequest";
import ClaimDetail from "../models/ClaimDetail";
import { ClaimSteps } from "../models/Step";
import type { GuardFn } from "./index";
import { Issue } from "../errors";
import { UploadType } from "../pages/applications/upload/index";
import { fields as addressFields } from "../pages/applications/address";
import { fields as changeRequestTypeFields } from "../pages/applications/modify/type";
import { fields as dateOfBirthFields } from "../pages/applications/date-of-birth";
import { fields as dateOfChildFields } from "../pages/applications/date-of-child";
import { fields as dateOfHireFields } from "../pages/applications/date-of-hire";
import { fields as dateOfSeparationFields } from "../pages/applications/date-of-separation";
import dayjs from "dayjs";
import { fields as departmentFields } from "../pages/applications/department";
import { fields as employerBenefitsFields } from "../pages/applications/employer-benefits";
import { fields as employerNameFields } from "../pages/applications/employer-name";
import { fields as employmentStatusFields } from "../pages/applications/employment-status";
import { fields as ethnicityFields } from "../pages/applications/ethnicity";
import { fields as familyMemberDateOfBirthFields } from "../pages/applications/family-member-date-of-birth";
import { fields as familyMemberNameFields } from "../pages/applications/family-member-name";
import { fields as familyMemberRelationshipFields } from "../pages/applications/family-member-relationship";
import { fields as genderFields } from "../pages/applications/gender";
import { fields as intermittentFrequencyFields } from "../pages/applications/intermittent-frequency";
import isBlank from "src/utils/isBlank";
import isDateInFuture from "src/utils/isDateInFuture";
import { isFeatureEnabled } from "src/services/featureFlags";
import { fields as languageFields } from "../pages/applications/language";
import { fields as leavePeriodContinuousFields } from "../pages/applications/leave-period-continuous";
import { fields as leavePeriodIntermittentFields } from "../pages/applications/leave-period-intermittent";
import { fields as leavePeriodReducedScheduleFields } from "../pages/applications/leave-period-reduced-schedule";
import { fields as leaveReasonFields } from "../pages/applications/leave-reason";
import { fields as medToBondingDateOfBirthFields } from "../pages/applications/modify/med-to-bonding/date-of-birth";
import { fields as medToBondingLeaveDatesFields } from "../pages/applications/modify/med-to-bonding/bonding-leave-dates";
import { fields as nameFields } from "../pages/applications/name";
import { fields as notifiedEmployerFields } from "../pages/applications/notified-employer";
import { fields as otherIncomesDetailsFields } from "../pages/applications/other-incomes-details";
import { fields as otherIncomesFields } from "../pages/applications/other-incomes";
import { fields as paymentMethodFields } from "../pages/applications/payment-method";
import { fields as phoneNumberFields } from "../pages/applications/phone-number";
import { fields as previousLeavesFields } from "../pages/applications/previous-leaves";
import { fields as raceFields } from "../pages/applications/race";
import { fields as reducedLeaveScheduleFields } from "../pages/applications/reduced-leave-schedule";
import routes from "../routes";
import { fields as scheduleFixedFields } from "../pages/applications/schedule-fixed";
import { fields as scheduleVariableFields } from "../pages/applications/schedule-variable";
import { fields as ssnFields } from "../pages/applications/ssn";
import { fields as stateIdFields } from "../pages/applications/state-id";
import { fields as taxWithholdingFields } from "../pages/applications/tax-withholding";
import { fields as workPatternTypeFields } from "../pages/applications/work-pattern-type";

export interface ClaimantFlowContext {
  claim?: BenefitsApplication;
  isAdditionalDoc?: boolean;
  changeRequest?: ChangeRequest;
  claimDetail?: ClaimDetail;
  requestContext?: string;
  warnings?: Issue[];
  nextBenefitYear?: BenefitYear | null;
  latestBenefitYear?: BenefitYear | null;
  user?: User;
  currentBenefitYear?: BenefitYear | null;
  isAnotherFormOfUploadCertificationType?: boolean;
  userProfileCheckForUpdates?: UserProfileCheckForUpdatesResponse | null;
  userProfileHasUsableDataForApplication?: UserProfileHasUsableDataForApplicationResponse | null;
}

/**
 *
 * @see https://xstate.js.org/docs/guides/guards.html#custom-guards
 * @see https://github.com/statelyai/xstate/issues/414#issuecomment-480664208
 * Custom guard that requires multiple guards to be true
 */
const every = (...guardKeys: string[]) => ({
  type: "every",
  guardKeys,
});

/**
 * @see https://xstate.js.org/docs/guides/guards.html
 */
export const guards: { [guardName: string]: GuardFn } = {
  // claimants upload additional docs after the claim is completed.
  // claimants will either be routed to the status page vs. the checklist
  // if they are uploading an additional doc.
  isAdditionalDoc: ({ isAdditionalDoc }) => isAdditionalDoc === true,
  isCaringLeave: ({ claim, claimDetail }) =>
    claim?.isCaringLeave === true || claimDetail?.hasCaringLeavePeriod === true,
  isNonPregnancyMedicalLeave: ({ claimDetail }) =>
    claimDetail?.isNonPregnancyMedicalLeave === true,
  isPregnancyLeave: ({ claimDetail }) =>
    claimDetail?.hasPregnancyLeavePeriod === true,
  isMedicalOrPregnancyLeave: ({ claim }) =>
    claim?.isMedicalOrPregnancyLeave === true,
  isBondingLeave: ({ claim }) => claim?.isBondingLeave === true,
  hasEmployerWithDepartments: ({ claim }) =>
    get(claim, "employment_status") === EmploymentStatus.employed &&
    get(claim, "employer_organization_units", []).length > 0,
  isEmployed: ({ claim }) =>
    get(claim, "employment_status") === EmploymentStatus.employed,
  isCompleted: ({ claim }) => claim?.isCompleted === true,
  hasStateId: ({ claim }) => claim?.has_state_id === true,
  hasEmployerBenefits: ({ claim }) => claim?.has_employer_benefits === true,
  hasIntermittentLeavePeriods: ({ claim }) =>
    claim?.has_intermittent_leave_periods === true,
  hasPreviousLeavesOtherReason: ({ claim }) =>
    claim?.has_previous_leaves_other_reason === true,
  hasPreviousLeavesSameReason: ({ claim }) =>
    claim?.has_previous_leaves_same_reason === true,
  hasReducedScheduleLeavePeriods: ({ claim }) =>
    claim?.has_reduced_schedule_leave_periods === true,
  hasOtherIncomes: ({ claim }) => claim?.has_other_incomes === true,
  isFixedWorkPattern: ({ claim }) =>
    get(claim, "work_pattern.work_pattern_type") === WorkPatternType.fixed,
  isVariableWorkPattern: ({ claim }) =>
    get(claim, "work_pattern.work_pattern_type") === WorkPatternType.variable,
  isConcurrentEmploymentQuestionsEnabled: () => {
    return isFeatureEnabled("enableConcurrentEmploymentQuestions");
  },
  hasUserNotFoundError: ({ warnings }) => {
    return (warnings || []).some(
      (warning) =>
        warning.rule === "require_contributing_employer" ||
        warning.rule === "require_employee"
    );
  },
  doLeaveDatesCrossBenefitYears: ({ claim }) =>
    claim !== undefined && claim.hasLeaveSpanningBenefitYears(),
  doLeaveDatesCrossBenefitYearsAndIsEarliestSubmissionDateInFuture: ({
    claim,
  }) => {
    return (
      claim !== undefined &&
      claim.hasLeaveSpanningBenefitYears() &&
      !claim.isEarliestSubmissionDateInFuture
    );
  },
  isSubmittedApplicationSplit: ({ claim }) =>
    !isBlank(claim?.split_into_application_id),
  isDateOfBirthInFuture: ({ changeRequest }) =>
    isDateInFuture(changeRequest?.date_of_birth),
  isDateOfBirthTwoWeeksPastEndDate: ({ changeRequest, claimDetail }) =>
    dayjs(changeRequest?.date_of_birth) >=
    dayjs(claimDetail?.endDate).add(14, "day"),
  isLeaveExtension: ({ changeRequest }) =>
    changeRequest !== undefined && changeRequest?.isExtension,
  isInModificationsContext: ({ requestContext }) => requestContext === "modify",
  isInApplicationsContext: ({ requestContext }) =>
    requestContext === "application",
  isMedToBonding: ({ changeRequest }) =>
    changeRequest !== undefined && changeRequest?.isMedToBonding,
  isAnotherFormOfCertification: ({ isAnotherFormOfUploadCertificationType }) =>
    isAnotherFormOfUploadCertificationType === true,
  willUploadCertificationDocumentLater: ({
    isAnotherFormOfUploadCertificationType,
  }) =>
    isAnotherFormOfUploadCertificationType === true &&
    isFeatureEnabled("documentUploadOptional"),
  doChangeRequestDatesSpanBenefitYears: ({
    changeRequest,
    currentBenefitYear,
    nextBenefitYear,
    latestBenefitYear,
    user,
  }): boolean => {
    const doNotRouteToBenefitReviewPage =
      // we don't show benefit year information when a claimant has multiple ssns
      // due to the benefit years being linked at the user level
      // this causes conflicts in the way we are retrieving the BY info based on claimant
      (user && user.has_multiple_tax_identifiers) ||
      !changeRequest ||
      (!changeRequest?.isExtension && !changeRequest?.isMedToBonding) ||
      !changeRequest.end_date;

    if (doNotRouteToBenefitReviewPage) {
      return false;
    }
    const { extensionSpansNextCalculatedBenefitYear } = nextBenefitYearData(
      currentBenefitYear || latestBenefitYear,
      nextBenefitYear,
      changeRequest
    );
    return extensionSpansNextCalculatedBenefitYear;
  },
  canUpdateUserProfileWithApplication: ({
    claim,
    userProfileCheckForUpdates,
  }): boolean => {
    if (!isFeatureEnabled("enableUniversalProfilePilot")) {
      return false;
    }

    // user explicitly chose to not use the profile, we should not prompt to
    // save to it
    if (
      !claim ||
      (Array.isArray(claim.fields_to_use_from_user_profile) &&
        claim.fields_to_use_from_user_profile.length === 0)
    ) {
      return false;
    }

    // there are no updates that could be made
    if (isEmpty(userProfileCheckForUpdates?.profile_updates)) {
      return false;
    }

    return true;
  },
  hasUserProfileWithUsableDataForApplication: ({
    userProfileHasUsableDataForApplication,
  }): boolean => {
    if (!isFeatureEnabled("enableUniversalProfilePilot")) {
      return false;
    }

    // there is no data that could be used
    if (isEmpty(userProfileHasUsableDataForApplication?.usable_fields)) {
      return false;
    }

    return true;
  },
  every: (ctx, event, condMeta) => {
    const cond = condMeta.cond as ReturnType<typeof every>;
    return (
      cond.guardKeys.every((key) => guards[key](ctx, event, condMeta)) === true
    );
  },
};

/**
 * Events shared by checklist and review
 */
const checklistEvents = {
  VERIFY_ID: routes.applications.name,
  LEAVE_DETAILS: routes.applications.leaveReason,
  OTHER_LEAVE: routes.applications.previousLeavesIntro,
  EMPLOYER_INFORMATION: routes.applications.employmentStatus,
  PAYMENT: routes.applications.paymentMethod,
  TAX_WITHHOLDING: routes.applications.taxWithholding,
  UPLOAD_CERTIFICATION: [
    {
      target: routes.applications.uploadCertificationType,
      cond: "isCaringLeave",
    },
    {
      target: routes.applications.uploadCertificationType,
      cond: "isMedicalOrPregnancyLeave",
    },
    { target: routes.applications.uploadCertification },
  ],
  UPLOAD_ID: routes.applications.uploadId,
  UPLOAD_MILITARY_EXIGENCY: routes.applications.uploadCertificationMethod,
  UPLOAD_CARE_FOR_FAMILY_MEMBER: routes.applications.uploadCertificationMethod,
  UPLOAD_FAMILY_MEMBER_SERVICE_PROOF:
    routes.applications.uploadCertificationMethod,
  UPLOAD_SERVICE_MEMBER_PROOF: routes.applications.uploadCertificationMethod,
};

/**
 * Events shared by all upload docs pages
 */
const uploadDocEvents = {
  CONTINUE: [
    {
      target: routes.applications.modify.review,
      cond: "isInModificationsContext",
    },
    {
      target: routes.applications.status.claim,
      cond: "isAdditionalDoc",
    },
    {
      target: routes.applications.checklist,
    },
  ],
};

interface ConditionalEvent {
  target: string;
  cond?: string | { type: string; guardKeys: string[] };
}

export interface ClaimantFlowState {
  meta?: {
    applicableRules?: string[];
    fields?: string[];
    step?: string;
  };
  on: { [event: string]: string | ConditionalEvent[] };
}

const claimantFlow: {
  states: { [route: string]: ClaimantFlowState };
} = {
  states: {
    [routes.applications.getReady]: {
      meta: {},
      on: {
        IMPORT_APPLICATION: routes.applications.importClaim,
        START_APPLICATION: routes.applications.start,
        SHOW_APPLICATIONS: routes.applications.index,
      },
    },
    [routes.applications.start]: {
      meta: {},
      on: {
        CREATE_CLAIM: [
          {
            target: routes.applications.useProfile,
            cond: "hasUserProfileWithUsableDataForApplication",
          },
          { target: routes.applications.checklist },
        ],
      },
    },
    [routes.applications.importClaim]: {
      on: {
        CONTINUE: routes.applications.index,
      },
    },
    [routes.applications.index]: {
      meta: {},
      on: {
        CONTINUE: routes.applications.uploadDocsOptions,
        IMPORT_APPLICATION: routes.applications.importClaim,
        NEW_APPLICATION: routes.applications.getReady,
        VIEW_PAYMENT_DETAIL: routes.applications.status.paymentDetail,
        VIEW_PAYMENTS: routes.applications.status.payments,
        REPORT_INTERMITTENT_LEAVE:
          routes.applications.status.reportIntermittentLeave,
        STATUS: routes.applications.status.claim,
      },
    },
    [routes.applications.status.reportIntermittentLeave]: {
      on: {
        REPORT_INTERMITTENT_LEAVE:
          routes.applications.status.reportIntermittentLeave,
        STATUS: routes.applications.status.claim,
        VIEW_PAYMENTS: routes.applications.status.payments,
      },
    },
    [routes.applications.checklist]: {
      meta: {},
      on: {
        REVIEW_AND_CONFIRM: [
          {
            target: routes.applications.bondingLeaveAttestation,
            cond: "isBondingLeave",
          },
          {
            target: routes.applications.caringLeaveAttestation,
            cond: "isCaringLeave",
          },
          { target: routes.applications.review },
        ],
        ...checklistEvents,
      },
    },
    [routes.applications.success]: {
      meta: {},
      on: {
        CONTINUE: routes.applications.getReady,
      },
    },
    [routes.applications.name]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: nameFields,
      },
      on: {
        CONTINUE: routes.applications.dateOfBirth,
      },
    },
    [routes.applications.dateOfBirth]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: dateOfBirthFields,
      },
      on: {
        CONTINUE: routes.applications.phoneNumber,
      },
    },
    [routes.applications.phoneNumber]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: phoneNumberFields,
      },
      on: {
        CONTINUE: routes.applications.address,
      },
    },
    [routes.applications.address]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: addressFields,
      },
      on: {
        CONTINUE: routes.applications.language,
      },
    },
    [routes.applications.language]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: languageFields,
      },
      on: {
        CONTINUE: routes.applications.stateId,
      },
    },
    [routes.applications.stateId]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: stateIdFields,
      },
      on: {
        CONTINUE: routes.applications.ssn,
      },
    },
    [routes.applications.uploadId]: {
      meta: {
        step: ClaimSteps.uploadId,
        // user fields are not currently evaluated
        // when determining step completeness
        fields: [],
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.index,
            cond: "isCompleted",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.ssn]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: ssnFields,
      },
      on: {
        CONTINUE: routes.applications.gender,
      },
    },
    [routes.applications.gender]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: genderFields,
      },
      on: {
        CONTINUE: routes.applications.ethnicity,
      },
    },
    [routes.applications.ethnicity]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: ethnicityFields,
      },
      on: {
        CONTINUE: routes.applications.race,
      },
    },
    [routes.applications.race]: {
      meta: {
        step: ClaimSteps.verifyId,
        fields: raceFields,
      },
      on: {
        CONTINUE: routes.applications.checklist,
      },
    },
    [routes.applications.leaveReason]: {
      meta: {
        step: ClaimSteps.leaveDetails,
        fields: leaveReasonFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.leavePeriodContinuous,
            cond: "isMedicalOrPregnancyLeave",
          },
          {
            target: routes.applications.dateOfChild,
            cond: "isBondingLeave",
          },
          {
            target: routes.applications.familyMemberRelationship,
            cond: "isCaringLeave",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.reducedLeaveSchedule]: {
      meta: {
        applicableRules: ["min_reduced_leave_minutes"],
        step: ClaimSteps.leaveDetails,
        fields: reducedLeaveScheduleFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.leaveSpansBenefitYearsReduced,
            cond: "doLeaveDatesCrossBenefitYears",
          },
          {
            target: routes.applications.leavePeriodIntermittent,
          },
        ],
      },
    },
    [routes.applications.uploadCertification]: {
      meta: {
        step: ClaimSteps.uploadCertification,
        fields: [],
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.index,
            cond: "isCompleted",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.uploadCertificationMethod]: {
      meta: {
        step: ClaimSteps.uploadCertification,
        fields: [],
      },
      on: {
        CONTINUE: routes.applications.uploadCertification,
      },
    },
    [routes.applications.uploadCertificationType]: {
      meta: {
        step: ClaimSteps.uploadCertification,
        fields: [],
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.uploadCertificationLater,
            cond: "willUploadCertificationDocumentLater",
          },
          {
            target: routes.applications.uploadCertificationNotAccepted,
            cond: "isAnotherFormOfCertification",
          },
          {
            target: routes.applications.uploadCertificationMethod,
          },
        ],
      },
    },
    [routes.applications.uploadCertificationNotAccepted]: {
      meta: {
        step: ClaimSteps.uploadCertification,
        fields: [],
      },
      on: {
        CHECKLIST: routes.applications.checklist,
        CONTINUE: routes.applications.uploadCertificationMethod,
      },
    },
    [routes.applications.uploadCertificationLater]: {
      meta: {
        step: ClaimSteps.uploadCertification,
        fields: [],
      },
      on: {
        CONTINUE: routes.applications.review,
      },
    },
    [routes.applications.upload.certificationMethod]: {
      on: {
        [UploadType.medical_certification]:
          routes.applications.upload.medicalCertification,
        [UploadType.pregnancy_medical_certification]:
          routes.applications.upload.pregnancyCertification,
        [UploadType.caring_leave_certification]:
          routes.applications.upload.caringCertification,
      },
    },
    [routes.applications.upload.index]: {
      on: {
        [UploadType.mass_id]: routes.applications.upload.stateId,
        [UploadType.non_mass_id]: routes.applications.upload.otherId,
        [UploadType.medical_certification]:
          routes.applications.upload.certificationMethod,
        [UploadType.proof_of_birth]:
          routes.applications.upload.bondingProofOfBirth,
        [UploadType.proof_of_placement]:
          routes.applications.upload.bondingProofOfPlacement,
        [UploadType.pregnancy_medical_certification]:
          routes.applications.upload.certificationMethod,
        [UploadType.caring_leave_certification]:
          routes.applications.upload.certificationMethod,
        [UploadType.military_caregiver_form]:
          routes.applications.upload.militaryCaregiver,
        [UploadType.military_caregiver_proof]:
          routes.applications.upload.militaryCaregiverProof,
        [UploadType.military_exigency_form]:
          routes.applications.upload.militaryExigency,
        [UploadType.military_exigency_proof]:
          routes.applications.upload.militaryExigencyProof,
      },
    },
    [routes.applications.upload.stateId]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.otherId]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.medicalCertification]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.bondingProofOfBirth]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.bondingProofOfPlacement]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.pregnancyCertification]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.caringCertification]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.militaryCaregiver]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.militaryCaregiverProof]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.militaryExigency]: {
      on: uploadDocEvents,
    },
    [routes.applications.upload.militaryExigencyProof]: {
      on: uploadDocEvents,
    },
    [routes.applications.dateOfChild]: {
      meta: {
        step: ClaimSteps.leaveDetails,
        fields: dateOfChildFields,
      },
      on: {
        CONTINUE: routes.applications.leavePeriodContinuous,
      },
    },
    [routes.applications.leavePeriodContinuous]: {
      meta: {
        applicableRules: [
          "disallow_12mo_continuous_leave_period",
          "disallow_caring_leave_before_july",
        ],
        step: ClaimSteps.leaveDetails,
        fields: leavePeriodContinuousFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.leaveSpansBenefitYearsContinuous,
            cond: "doLeaveDatesCrossBenefitYearsAndIsEarliestSubmissionDateInFuture",
          },
          {
            target: routes.applications.leavePeriodReducedSchedule,
          },
        ],
      },
    },
    [routes.applications.leavePeriodReducedSchedule]: {
      meta: {
        applicableRules: [
          "disallow_12mo_reduced_leave_period",
          "disallow_caring_leave_before_july",
        ],
        step: ClaimSteps.leaveDetails,
        fields: leavePeriodReducedScheduleFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.reducedLeaveSchedule,
            cond: "hasReducedScheduleLeavePeriods",
          },
          {
            target: routes.applications.leavePeriodIntermittent,
          },
        ],
      },
    },
    [routes.applications.leavePeriodIntermittent]: {
      meta: {
        step: ClaimSteps.leaveDetails,
        fields: leavePeriodIntermittentFields,
        applicableRules: [
          "disallow_12mo_intermittent_leave_period",
          "disallow_caring_leave_before_july",
          // This page is after the Continuous and Reduced Schedule pages,
          // so on this page is where we can surface validation issues
          // related to the following rules:
          "disallow_hybrid_intermittent_leave",
          "min_leave_periods",
        ],
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.intermittentFrequency,
            cond: "hasIntermittentLeavePeriods",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.intermittentFrequency]: {
      meta: {
        step: ClaimSteps.leaveDetails,
        fields: intermittentFrequencyFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.leaveSpansBenefitYearsIntermittent,
            cond: "doLeaveDatesCrossBenefitYears",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.leaveSpansBenefitYearsContinuous]: {
      meta: {
        step: ClaimSteps.leaveDetails,
      },
      on: {
        CONTINUE: routes.applications.leavePeriodReducedSchedule,
      },
    },
    [routes.applications.leaveSpansBenefitYearsIntermittent]: {
      meta: {
        step: ClaimSteps.leaveDetails,
      },
      on: {
        CONTINUE: routes.applications.checklist,
      },
    },
    [routes.applications.leaveSpansBenefitYearsReduced]: {
      meta: {
        step: ClaimSteps.leaveDetails,
      },
      on: {
        CONTINUE: routes.applications.leavePeriodIntermittent,
      },
    },
    [routes.applications.previousLeavesIntro]: {
      meta: {
        step: ClaimSteps.otherLeave,
      },
      on: {
        CONTINUE: [{ target: routes.applications.previousLeaves }],
      },
    },
    [routes.applications.previousLeaves]: {
      meta: {
        step: ClaimSteps.otherLeave,
        fields: previousLeavesFields,
      },
      on: {
        CONTINUE: routes.applications.paidTimeOffExplainer,
      },
    },
    [routes.applications.paidTimeOffExplainer]: {
      meta: {
        step: ClaimSteps.otherLeave,
      },
      on: {
        CONTINUE: routes.applications.employerBenefitsIntro,
      },
    },
    [routes.applications.employerBenefitsIntro]: {
      meta: {
        step: ClaimSteps.otherLeave,
      },
      on: {
        CONTINUE: routes.applications.employerBenefits,
      },
    },
    [routes.applications.employerBenefits]: {
      meta: {
        step: ClaimSteps.otherLeave,
        fields: employerBenefitsFields,
      },
      on: {
        CONTINUE: routes.applications.otherIncomes,
      },
    },

    [routes.applications.otherIncomes]: {
      meta: {
        step: ClaimSteps.otherLeave,
        fields: otherIncomesFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.otherIncomesDetails,
            cond: "hasOtherIncomes",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.otherIncomesDetails]: {
      meta: {
        step: ClaimSteps.otherLeave,
        fields: otherIncomesDetailsFields,
      },
      on: {
        CONTINUE: routes.applications.checklist,
      },
    },
    [routes.applications.employmentStatus]: {
      meta: {
        // TODO (PFMLPB-4447) Remove applicableRules all together
        applicableRules: [],
        step: ClaimSteps.employerInformation,
        fields: employmentStatusFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.noMatchFound,
            cond: "hasUserNotFoundError",
          },
          {
            target: routes.applications.department,
            cond: "hasEmployerWithDepartments",
          },
          {
            target: routes.applications.notifiedEmployer,
            cond: "isEmployed",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.noMatchFound]: {
      meta: {
        step: ClaimSteps.employerInformation,
      },
      on: {
        // if claimant successfully fixes the issue, continue through normal flow
        CONTINUE: [
          {
            target: routes.applications.beginAdditionalUserNotFoundInfo,
            cond: "hasUserNotFoundError",
          },
          {
            target: routes.applications.department,
            cond: "hasEmployerWithDepartments",
          },
          {
            target: routes.applications.notifiedEmployer,
            cond: "isEmployed",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.beginAdditionalUserNotFoundInfo]: {
      meta: {
        step: ClaimSteps.employerInformation,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.employerName,
          },
        ],
      },
    },
    [routes.applications.employerName]: {
      meta: {
        step: ClaimSteps.employerInformation,
        fields: employerNameFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.dateOfHire,
          },
        ],
      },
    },
    [routes.applications.dateOfHire]: {
      meta: {
        step: ClaimSteps.employerInformation,
        fields: dateOfHireFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.dateOfSeparation,
          },
        ],
      },
    },
    [routes.applications.dateOfSeparation]: {
      meta: {
        step: ClaimSteps.employerInformation,
        fields: dateOfSeparationFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.recentlyAcquiredOrMerged,
          },
        ],
      },
    },
    [routes.applications.recentlyAcquiredOrMerged]: {
      meta: {
        step: ClaimSteps.employerInformation,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.taxWithholding,
          },
        ],
      },
    },
    [routes.applications.department]: {
      meta: {
        step: ClaimSteps.employerInformation,
        fields: departmentFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.notifiedEmployer,
            cond: "isEmployed",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.notifiedEmployer]: {
      meta: {
        step: ClaimSteps.employerInformation,
        fields: notifiedEmployerFields,
      },
      on: {
        CONTINUE: routes.applications.workPatternType,
      },
    },
    [routes.applications.concurrentEmployment]: {
      meta: {},
      on: {
        CONTINUE: routes.applications.checklist,
      },
    },
    [routes.applications.paymentMethod]: {
      meta: {
        step: ClaimSteps.payment,
        fields: paymentMethodFields,
      },
      on: {
        CONTINUE: [
          {
            // TODO (PFMLPB-3822) Does this make sense if they no longer work at that employer?
            target: routes.applications.notifiedEmployer,
            cond: "hasUserNotFoundError",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.taxWithholding]: {
      meta: {
        step: ClaimSteps.taxWithholding,
        fields: taxWithholdingFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.paymentMethod,
            cond: "hasUserNotFoundError",
          },
          {
            target: routes.applications.checklist,
          },
        ],
      },
    },
    [routes.applications.bondingLeaveAttestation]: {
      meta: {
        step: ClaimSteps.reviewAndConfirm,
        fields: [],
      },
      on: {
        CONTINUE: routes.applications.review,
      },
    },
    [routes.applications.review]: {
      meta: {
        step: ClaimSteps.reviewAndConfirm,
        fields: [],
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.success,
            cond: "isCompleted",
          },
          {
            target: routes.applications.index,
            cond: "isSubmittedApplicationSplit",
          },
          {
            target: routes.applications.saveProfile,
            cond: "canUpdateUserProfileWithApplication",
          },
          {
            target: routes.applications.checklist,
          },
        ],
        CHECKLIST: routes.applications.checklist,
        ...checklistEvents,
      },
    },
    [routes.applications.saveProfile]: {
      meta: {
        step: ClaimSteps.reviewAndConfirm,
        fields: [],
      },
      on: {
        CONTINUE: routes.applications.checklist,
      },
    },
    [routes.applications.uploadDocsOptions]: {
      meta: {},
      on: {
        UPLOAD_ID: routes.applications.uploadId,
        UPLOAD_MASS_ID: routes.applications.uploadId,
        UPLOAD_CERTIFICATION: [
          {
            target: routes.applications.uploadCertificationMethod,
            cond: "isCaringLeave",
          },
          {
            target: routes.applications.uploadCertificationMethod,
            cond: "isMedicalOrPregnancyLeave",
          },
          { target: routes.applications.uploadCertification },
        ],
      },
    },
    [routes.applications.workPatternType]: {
      meta: {
        step: ClaimSteps.employerInformation,
        fields: workPatternTypeFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.scheduleFixed,
            cond: "isFixedWorkPattern",
          },
          {
            target: routes.applications.scheduleVariable,
            cond: "isVariableWorkPattern",
          },
        ],
      },
    },
    [routes.applications.scheduleFixed]: {
      meta: {
        step: ClaimSteps.employerInformation,
        fields: scheduleFixedFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.concurrentEmployment,
            cond: "isConcurrentEmploymentQuestionsEnabled",
          },
          { target: routes.applications.checklist },
        ],
      },
    },
    [routes.applications.scheduleVariable]: {
      meta: {
        step: ClaimSteps.employerInformation,
        fields: scheduleVariableFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.concurrentEmployment,
            cond: "isConcurrentEmploymentQuestionsEnabled",
          },
          { target: routes.applications.checklist },
        ],
      },
    },
    [routes.applications.familyMemberDateOfBirth]: {
      meta: {
        step: ClaimSteps.leaveDetails,
        fields: familyMemberDateOfBirthFields,
      },
      on: {
        CONTINUE: routes.applications.leavePeriodContinuous,
      },
    },
    [routes.applications.familyMemberName]: {
      meta: {
        step: ClaimSteps.leaveDetails,
        fields: familyMemberNameFields,
      },
      on: {
        CONTINUE: routes.applications.familyMemberDateOfBirth,
      },
    },
    [routes.applications.familyMemberRelationship]: {
      meta: {
        step: ClaimSteps.leaveDetails,
        fields: familyMemberRelationshipFields,
      },
      on: {
        CONTINUE: routes.applications.familyMemberName,
      },
    },
    [routes.applications.caringLeaveAttestation]: {
      meta: {
        step: ClaimSteps.reviewAndConfirm,
        fields: [],
      },
      on: {
        CONTINUE: routes.applications.review,
      },
    },
    [routes.applications.status.claim]: {
      on: {
        UPLOAD_PROOF_OF_BIRTH: routes.applications.upload.bondingProofOfBirth,
        UPLOAD_PROOF_OF_PLACEMENT:
          routes.applications.upload.bondingProofOfPlacement,
        UPLOAD_DOC_OPTIONS: routes.applications.upload.index,
        VIEW_PAYMENTS: routes.applications.status.payments,
        CONTINUE_APPEAL: routes.applications.appeals.reason,
        START_APPEAL: routes.applications.appeals.start,
        START_MODIFICATION: routes.applications.modify.index,
        CONTINUE_MODIFICATION: routes.applications.modify.type,
        VIEW_PAYMENT_DETAIL: routes.applications.status.paymentDetail,
        REPORT_INTERMITTENT_LEAVE:
          routes.applications.status.reportIntermittentLeave,
        START_TRANSITION_TO_BONDING:
          routes.applications.modify.medToBonding.index,
        CONTINUE_TRANSITION_TO_BONDING:
          routes.applications.modify.medToBonding.dateOfBirth,
        UPLOAD_APPEAL_DOCS: routes.applications.appeals.upload,
        DELETE_CHANGE_REQUEST: routes.applications.modify.deleteChangeRequest,
      },
    },
    [routes.applications.status.payments]: {
      on: {
        REPORT_INTERMITTENT_LEAVE:
          routes.applications.status.reportIntermittentLeave,
        STATUS: routes.applications.status.claim,
        VIEW_PAYMENT_DETAIL: routes.applications.status.paymentDetail,
      },
    },
    [routes.applications.status.paymentDetail]: {
      on: {
        STATUS: routes.applications.status.claim,
        VIEW_PAYMENTS: routes.applications.status.payments,
        REPORT_INTERMITTENT_LEAVE:
          routes.applications.status.reportIntermittentLeave,
      },
    },
    [routes.applications.modify.index]: {
      on: {
        CONTINUE: routes.applications.modify.type,
      },
    },
    [routes.applications.modify.type]: {
      meta: {
        fields: changeRequestTypeFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.modify.benefitYearInfoReview,
            cond: "doChangeRequestDatesSpanBenefitYears",
          },
          {
            target: routes.applications.upload.pregnancyCertification,
            cond: every("isLeaveExtension", "isPregnancyLeave"),
          },
          {
            target: routes.applications.upload.caringCertification,
            cond: every("isLeaveExtension", "isCaringLeave"),
          },
          {
            target: routes.applications.upload.medicalCertification,
            cond: every("isLeaveExtension", "isNonPregnancyMedicalLeave"),
          },
          {
            target: routes.applications.modify.review,
          },
        ],
      },
    },
    [routes.applications.modify.benefitYearInfoReview]: {
      on: {
        CONTINUE: [
          {
            target: routes.applications.modify.review,
            cond: every("isMedToBonding", "isDateOfBirthInFuture"),
          },
          {
            target: routes.applications.modify.medToBonding.proofOfBirth,
            cond: "isMedToBonding",
          },
          {
            target: routes.applications.upload.pregnancyCertification,
            cond: every("isLeaveExtension", "isPregnancyLeave"),
          },
          {
            target: routes.applications.upload.caringCertification,
            cond: every("isLeaveExtension", "isCaringLeave"),
          },
          {
            target: routes.applications.upload.medicalCertification,
            cond: every("isLeaveExtension", "isNonPregnancyMedicalLeave"),
          },
          {
            target: routes.applications.modify.review,
          },
        ],
      },
    },
    [routes.applications.modify.review]: {
      on: {
        CONTINUE: [
          {
            target: routes.applications.modify.success,
            cond: "isLeaveExtension",
          },
          {
            target: routes.applications.modify.medToBonding.success,
            cond: "isMedToBonding",
          },
          {
            target: routes.applications.status.claim,
          },
        ],
      },
    },
    [routes.applications.modify.success]: {
      on: {
        VIEW_STATUS: routes.applications.status.claim,
      },
    },
    [routes.applications.modify.deleteChangeRequest]: {
      on: {
        CONTINUE: routes.applications.status.claim,
      },
    },
    [routes.applications.modify.withdrawLeave]: {
      on: {
        VIEW_STATUS: routes.applications.status.claim,
        VIEW_APPLICATIONS: routes.applications.index,
      },
    },
    [routes.applications.modify.medToBonding.index]: {
      on: {
        CONTINUE: routes.applications.modify.medToBonding.dateOfBirth,
      },
    },
    [routes.applications.modify.medToBonding.dateOfBirth]: {
      meta: {
        fields: medToBondingDateOfBirthFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.modify.medToBonding.redirectToExtension,
            cond: "isDateOfBirthTwoWeeksPastEndDate",
          },
          {
            target: routes.applications.modify.medToBonding.bondingLeaveDates,
          },
        ],
      },
    },
    [routes.applications.modify.medToBonding.redirectToExtension]: {
      on: {
        CONTINUE: routes.applications.modify.type,
      },
    },
    [routes.applications.modify.medToBonding.proofOfBirth]: {
      on: {
        HAS_PROOF_OF_BIRTH: routes.applications.upload.bondingProofOfBirth,
        CONTINUE: routes.applications.modify.review,
      },
    },
    [routes.applications.modify.medToBonding.bondingLeaveDates]: {
      meta: {
        fields: medToBondingLeaveDatesFields,
      },
      on: {
        CONTINUE: [
          {
            target: routes.applications.modify.benefitYearInfoReview,
            cond: "doChangeRequestDatesSpanBenefitYears",
          },
          {
            target: routes.applications.modify.review,
            cond: "isDateOfBirthInFuture",
          },
          {
            target: routes.applications.modify.medToBonding.proofOfBirth,
          },
        ],
      },
    },
    [routes.applications.modify.medToBonding.success]: {
      on: {
        VIEW_STATUS: routes.applications.status.claim,
      },
    },
    [routes.applications.useProfile]: {
      meta: {},
      on: {
        CONTINUE: routes.applications.checklist,
      },
    },
  },
};

export default claimantFlow;
