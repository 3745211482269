import { compact } from "lodash";

/**
 * Returns full name accounting for any false values
 */
export function fullName(
  first_name: string | null,
  middle_name: string | null,
  last_name: string | null
) {
  return compact([first_name, middle_name, last_name]).join(" ");
}
