import React from "react";

interface ImageProps {
  src: string;
  alt: string;
  width: number;
  height: number;
}
interface SiteLogoProps {
  url: string;
  title?: string;
  image: ImageProps;
  siteName?: string;
}

const SiteLogo = (props: SiteLogoProps) => {
  return (
    <React.Fragment>
      <div className="ma__site-logo">
        <a href={props.url} title={props.title}>
          <img
            alt={props.image.alt}
            src={props.image.src}
            height={props.image.height}
            width={props.image.width}
            className="ma__image"
          />
          {props.siteName && <span>{props.siteName}</span>}
        </a>
      </div>
    </React.Fragment>
  );
};

export default SiteLogo;
