import React from "react";

/**
 * Replication of is.fn from is module
 * https://github.com/enricomarino/is/blob/cb4d31da1fe9e315e4bd917b063c636bf110e2b2/index.js
 * TODO (PFMLPB-8365): Investigate replacing this component w/ USWDS pagination
 */
const isFn = (value: unknown) => {
  const isAlert = typeof window !== "undefined" && value === window.alert;
  if (isAlert) {
    return true;
  }
  const str = Object.prototype.toString.call(value);
  return (
    str === "[object Function]" ||
    str === "[object GeneratorFunction]" ||
    str === "[object AsyncFunction]"
  );
};

interface PaginationProps {
  next: {
    disabled?: boolean;
    text: string;
    onClick: () => void;
  };
  prev: {
    disabled?: boolean;
    text: string;
    onClick?: ((arg: React.SyntheticEvent) => void) | null;
  };
  pages: Array<{
    active: boolean;
    text: string;
    onClick: ((arg: React.SyntheticEvent) => void) | null;
  }>;
}

const Pagination = (props: PaginationProps) => {
  const { prev, next, pages } = props;

  const handleClick = (
    event: React.SyntheticEvent,
    handleAnchorClick: typeof props.prev.onClick | typeof props.next.onClick
  ) => {
    event.preventDefault();
    if (isFn(handleAnchorClick) && handleAnchorClick) {
      handleAnchorClick(event);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent,
    handleAnchorClick: typeof props.prev.onClick | typeof props.next.onClick
  ) => {
    if (event.key === " " && isFn(handleAnchorClick) && handleAnchorClick) {
      event.preventDefault();
      handleAnchorClick(event);
    }
  };

  return (
    <div
      className="ma__pagination js-pagination"
      role="navigation"
      aria-label="Pagination Navigation"
      data-testid="pagination-navigation"
    >
      <nav className="ma__pagination__container">
        <a
          className={`ma__pagination__prev ${prev.disabled && " disabled"}`}
          role="button"
          href="#"
          onClick={(e) => handleClick(e, prev?.onClick)}
          onKeyDown={(e) => handleKeyDown(e, prev?.onClick)}
          aria-label={`Go to ${prev.text} page`}
          aria-disabled={prev.disabled}
          tabIndex={prev.disabled ? -1 : 0}
        >
          {prev.text}
        </a>
        {pages.map((page, pageIndex) => {
          const key = `pagination.item.${pageIndex}`;
          return page.text === "spacer" ? (
            <span key={key} className="ma__pagination__spacer">
              &hellip;
            </span>
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className={
                page.active
                  ? "ma__pagination__page is-active"
                  : "ma__pagination__page"
              }
              role="button"
              href="#"
              data-page={page.text}
              onClick={(e) => handleClick(e, page.onClick)}
              onKeyDown={(e) => handleKeyDown(e, page.onClick)}
              key={key}
              aria-label={`Go to Page ${page.text}`}
              tabIndex={0}
            >
              {page.text}
            </a>
          );
        })}
        <a
          className={`ma__pagination__next ${next.disabled && " disabled"}`}
          role="button"
          href="#"
          onClick={(e) => handleClick(e, next?.onClick)}
          onKeyDown={(e) => handleKeyDown(e, next?.onClick)}
          aria-label={`Go to ${next.text} page`}
          aria-disabled={next.disabled}
          tabIndex={next.disabled ? -1 : 0}
        >
          {next.text}
        </a>
      </nav>
    </div>
  );
};

export default Pagination;
