import withBenefitYears, {
  WithBenefitYearsProps,
} from "src/hoc/withBenefitYears";
import withChangeRequest, {
  WithChangeRequestProps,
} from "src/hoc/withChangeRequest";
import withClaimDetail, { WithClaimDetailProps } from "src/hoc/withClaimDetail";

import AbsencePeriodStatusTag from "src/components/AbsencePeriodStatusTag";
import { AppLogic } from "../../../../hooks/useAppLogic";
import Details from "src/components/core/Details";
import ExternalLink from "src/components/core/ExternalLink";
import Fieldset from "../../../../components/core/Fieldset";
import FormLabel from "../../../../components/core/FormLabel";
import Heading from "src/components/core/Heading";
import InputDate from "../../../../components/core/InputDate";
import QuestionPage from "../../../../components/QuestionPage";
import React from "react";
import ReviewRow from "../../../../components/ReviewRow";
import Tag from "src/components/core/Tag";
import { Trans } from "react-i18next";
import UpdateLeaveDatesBanner from "src/features/modify/UpdateLeaveDatesBanner";
import dayjs from "dayjs";
import formatDate from "../../../../utils/formatDate";
import { pick } from "lodash";
import routes from "../../../../routes";
import useFormState from "../../../../hooks/useFormState";
import useFunctionalInputProps from "../../../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../../../locales/i18n";

export const fields = ["change_request.start_date", "change_request.end_date"];

type BondingLeaveDatesProps = WithClaimDetailProps &
  WithChangeRequestProps &
  WithBenefitYearsProps & {
    query: {
      change_request_id: string;
      absence_id: string;
    };
    appLogic: AppLogic;
  };

export const BondingLeaveDates = (props: BondingLeaveDatesProps) => {
  const { t } = useTranslation();
  const { formState, updateFields } = useFormState(
    pick(props, fields).change_request
  );

  const bondingLeaveStartDay = dayjs(props.claim_detail?.endDate).add(1, "day");

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: props.appLogic.errors,
    formState,
    updateFields,
  });

  const handleSubmit = async () => {
    formState.start_date = bondingLeaveStartDay.format("YYYY-MM-DD");

    await props.appLogic.changeRequests.update(
      props.query.change_request_id,
      formState,
      undefined,
      {
        currentBenefitYear: props.currentBenefitYear,
        nextBenefitYear: props.nextBenefitYear,
        latestBenefitYear: props.latestBenefitYear,
      },
      props.user
    );
  };

  return (
    <QuestionPage
      title={t("pages.claimsModifyMedToBondingLeaveDates.title")}
      onSave={handleSubmit}
      alertBanner={<UpdateLeaveDatesBanner />}
    >
      <Heading level="2" size="1">
        {t("pages.claimsModifyMedToBondingLeaveDates.medToBondingModLegend")}
      </Heading>

      <Fieldset>
        <FormLabel
          hint
          small
          labelClassName="text-normal margin-top-05 margin-bottom-2"
        >
          <Trans
            i18nKey="pages.claimsModifyMedToBondingLeaveDates.medToBondingModLegendLegendHint"
            components={{
              "learn-about-bonding-leave-link": (
                <ExternalLink
                  href={routes.external.massgov.learnMoreAboutBondingLeave}
                />
              ),
              ul: <ul className="usa-list" />,
              li: <li />,
            }}
          />
        </FormLabel>
        <Details
          label={t(
            "pages.claimsModifyMedToBondingLeaveDates.delayBondingLeaveLabel"
          )}
        >
          <Trans
            i18nKey="pages.claimsModifyMedToBondingLeaveDates.delayBondingLeaveDescription"
            components={{
              "submit-app-link": (
                <ExternalLink href={routes.applications.start} />
              ),
            }}
          />
        </Details>

        <Details
          className="margin-bottom-4"
          label={t(
            "pages.claimsModifyMedToBondingLeaveDates.delayedBirthLabel"
          )}
        >
          <Trans
            i18nKey="pages.claimsModifyMedToBondingLeaveDates.delayedBirthDescription"
            components={{
              ul: <ul className="usa-list" />,
              li: <li />,
            }}
          />
        </Details>

        <ReviewRow
          noBorder
          level="3"
          label={[
            <AbsencePeriodStatusTag
              className="margin-right-1"
              key={"Approved"}
              request_decision={"Approved"}
            />,
            t("pages.claimsModifyMedToBondingLeaveDates.medicalLeaveLabel"),
          ]}
        >
          <Trans
            i18nKey="pages.claimsModifyMedToBondingLeaveDates.medicalLeaveValue"
            values={{
              startDate: formatDate(props.claim_detail?.startDate).short(),
              endDate: formatDate(props.claim_detail?.endDate).short(),
            }}
          />
        </ReviewRow>

        <InputDate
          {...getFunctionalInputProps("end_date")}
          label={[
            <Tag
              className="margin-right-1"
              state="warning"
              key={"Request"}
              label={t(
                "pages.claimsModifyMedToBondingLeaveDates.yourChangeRequestTag"
              )}
            />,
            t("pages.claimsModifyMedToBondingLeaveDates.bondingLeaveLabel"),
          ]}
          hint={
            <Trans
              i18nKey="pages.claimsModifyMedToBondingLeaveDates.bondingLeaveValue"
              values={{
                startDate: formatDate(bondingLeaveStartDay.toString()).short(),
              }}
            />
          }
          dayLabel={t("components.form.dateInputDayLabel")}
          monthLabel={t("components.form.dateInputMonthLabel")}
          yearLabel={t("components.form.dateInputYearLabel")}
          smallLabel
        />
      </Fieldset>
    </QuestionPage>
  );
};

export default withBenefitYears(
  withChangeRequest(withClaimDetail(BondingLeaveDates))
);
