import {
  AppealDocument,
  BenefitsApplicationDocument,
  DocumentTypeEnum,
  MilitaryDocumentTypeEnum,
  UserDocument,
} from "../models/Document";

import ApiResourceCollection from "../models/ApiResourceCollection";
import BaseApi from "./BaseApi";
import TempFile from "../models/TempFile";
import downloadDocument from "src/utils/downloadDocument";
import getDocumentFormData from "../utils/getDocumentFormData";
import routes from "../routes";

export default class DocumentsApi extends BaseApi {
  get basePath() {
    // The path depends on the method being called
    return "";
  }

  get namespace() {
    return "documents";
  }

  /**
   * Attach a document to an application/claim.
   * @param mark_evidence_received - Set the flag used to indicate whether
   * the doc is ready for review or not. Docs added to a claim that was completed
   * through a channel other than the Portal require this flag being set to `true`.
   */
  attachApplicationDocument = async (
    application_id: string,
    file: TempFile,
    document_type: DocumentTypeEnum | MilitaryDocumentTypeEnum,
    mark_evidence_received: boolean
  ) => {
    const formData = getDocumentFormData(
      file,
      document_type,
      mark_evidence_received
    );

    const { data } = await this.request<BenefitsApplicationDocument>(
      "POST",
      `${routes.api.applications}/${application_id}/documents`,
      formData,
      { multipartForm: true }
    );

    return {
      document: data,
    };
  };

  /**
   * Attach a document to an appeal.
   */
  attachAppealDocument = async (
    appeal_id: string,
    file: TempFile,
    document_type: DocumentTypeEnum
  ) => {
    const formData = getDocumentFormData(file, document_type, false);

    const { data } = await this.request<AppealDocument>(
      "POST",
      `${routes.api.appeals}/${appeal_id}/documents`,
      formData,
      { multipartForm: true }
    );

    return {
      document: data,
    };
  };

  /**
   * Attach a document to a change request and the underlying claim.
   * These documents will be viewable for the overall claim and will be returned by #getDocuments. This API
   * call will also mark the document as received on the change request in the API.
   */
  attachChangeRequestDocument = async (
    change_request_id: string,
    file: TempFile,
    document_type: DocumentTypeEnum
  ) => {
    const formData = getDocumentFormData(file, document_type, false);

    const { data } = await this.request<BenefitsApplicationDocument>(
      "POST",
      `${routes.api.changeRequest}/${change_request_id}/documents`,
      formData,
      { multipartForm: true }
    );

    return {
      document: data,
    };
  };

  /**
   * Load all documents for an application
   */
  getDocuments = async (application_id: string) => {
    const { data } = await this.request<BenefitsApplicationDocument[]>(
      "GET",
      `${routes.api.applications}/${application_id}/documents`
    );

    return {
      documents: new ApiResourceCollection<BenefitsApplicationDocument>(
        "fineos_document_id",
        data
      ),
    };
  };

  /**
   * Load all documents for an appeal
   */
  getAppealDocuments = async (appeal_id: string) => {
    const { data } = await this.request<AppealDocument[]>(
      "GET",
      `${routes.api.appeals}/${appeal_id}/documents`
    );

    return {
      appealDocuments: new ApiResourceCollection<AppealDocument>(
        "fineos_document_id",
        data
      ),
    };
  };

  /**
   * Load all user documents
   */
  getUserDocuments = async (user_id: string, document_type?: string) => {
    const { data } = await this.request<UserDocument[]>(
      "GET",
      !!document_type
        ? `${routes.api.users}/${user_id}/documents?document_type=${document_type}`
        : `${routes.api.users}/${user_id}/documents`
    );
    return {
      documents: new ApiResourceCollection<UserDocument>(
        "fineos_document_id",
        data
      ),
    };
  };

  /**
   * @param document instance of BenefitsApplicationDocument to download
   */
  downloadDocument = async (document: BenefitsApplicationDocument) => {
    const { application_id, content_type, fineos_document_id } = document;
    const subPath = `${routes.api.applications}/${application_id}/documents/${fineos_document_id}`;
    return await downloadDocument(
      this.basePath,
      subPath,
      this.namespace,
      content_type
    );
  };

  /**
   * @param document instance of UserDocument to download
   */
  downloadUserDocument = async (user_id: string, document: UserDocument) => {
    const { content_type, fineos_document_id } = document;
    const subPath = `${routes.api.users}/${user_id}/documents/${fineos_document_id}`;
    return await downloadDocument(
      this.basePath,
      subPath,
      this.namespace,
      content_type
    );
  };
}
