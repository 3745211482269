import Appeal from "../models/Appeal";
import BaseApi from "./BaseApi";
import routes from "../routes";

export default class AppealsApi extends BaseApi {
  get basePath() {
    return routes.api.appeals;
  }

  get namespace() {
    return "appeals";
  }

  complete = async (appealId: string) => {
    const { data } = await this.request<Appeal>("POST", `${appealId}/complete`);

    return { appeal: new Appeal(data) };
  };

  confirmDocuments = (appealId: string, fineos_document_ids: string[]) => {
    this.request<Appeal>("POST", `${appealId}/confirm-documents`, {
      fineos_document_ids,
    });
  };

  create = async (requestData: Partial<Appeal>) => {
    const { data } = await this.request<Appeal>("POST", "", requestData);

    return { appeal: new Appeal(data) };
  };

  get = async (appealId: string) => {
    const { data } = await this.request<Appeal>("GET", appealId);

    return { appeal: new Appeal(data) };
  };

  search = async (fineos_absence_id: string) => {
    const { data } = await this.request<Appeal[]>("POST", "search", {
      terms: { fineos_absence_id },
    });

    return { appeals: data.map((d) => new Appeal(d)) };
  };

  update = async (appealId: string, requestData: Partial<Appeal>) => {
    const { data, warnings } = await this.request<Appeal>(
      "PATCH",
      appealId,
      requestData
    );

    return { appeal: new Appeal(data), warnings };
  };
}
