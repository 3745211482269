import { get, pick } from "lodash";
import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "../../hoc/withBenefitsApplication";

import InputChoiceGroup from "../../components/core/InputChoiceGroup";
import LanguageDescription from "../../models/LanguageEnum";
import QuestionPage from "../../components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import useFormState from "../../hooks/useFormState";
import useFunctionalInputProps from "../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../locales/i18n";

export const fields = ["claim.language"];

export const Language = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const { formState, updateFields } = useFormState(pick(props, fields).claim);

  const handleSave = async () => {
    await appLogic.benefitsApplications.update(claim.application_id, formState);
  };

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const language = get(formState, "language");

  const languageNotListedHint = !(
    language === LanguageDescription.not_listed
  ) ? undefined : (
    <Trans
      data-testid="user-options-dropdown"
      i18nKey="pages.claimsLanguage.choiceLanguageNotListedHint"
      components={{
        "contact-center-phone-link": (
          <a href={`tel:${t("shared.contactCenterPhoneNumber")}`} />
        ),
      }}
    />
  );

  return (
    <QuestionPage
      title={t("pages.claimsLanguage.title")}
      dataCy="language-form"
      onSave={handleSave}
    >
      <InputChoiceGroup
        {...getFunctionalInputProps("language")}
        choices={[
          {
            checked: language === LanguageDescription.english,
            label: t("pages.claimsLanguage.choiceEnglish"),
            value: LanguageDescription.english,
            className: "pretranslated-multilanguage-content",
          },
          {
            checked: language === LanguageDescription.spanish,
            label: t("pages.claimsLanguage.choiceSpanish"),
            value: LanguageDescription.spanish,
            className: "pretranslated-multilanguage-content",
          },
          {
            checked: language === LanguageDescription.haitian_creole,
            label: t("pages.claimsLanguage.choiceHaitianCreole"),
            value: LanguageDescription.haitian_creole,
            className: "pretranslated-multilanguage-content",
          },
          {
            checked: language === LanguageDescription.portuguese,
            label: t("pages.claimsLanguage.choicePortuguese"),
            value: LanguageDescription.portuguese,
            className: "pretranslated-multilanguage-content",
          },
          {
            checked: language === LanguageDescription.chinese_simplified,
            label: t("pages.claimsLanguage.choiceChineseSimplified"),
            value: LanguageDescription.chinese_simplified,
            className: "pretranslated-multilanguage-content",
          },
          {
            checked: language === LanguageDescription.vietnamese,
            label: t("pages.claimsLanguage.choiceVietnamese"),
            value: LanguageDescription.vietnamese,
            className: "pretranslated-multilanguage-content",
          },
          {
            checked: language === LanguageDescription.french,
            label: t("pages.claimsLanguage.choiceFrench"),
            value: LanguageDescription.french,
            className: "pretranslated-multilanguage-content",
          },
          {
            checked: language === LanguageDescription.not_listed,
            label: t("pages.claimsLanguage.choiceLanguageNotListed"),
            value: LanguageDescription.not_listed,
            hint: languageNotListedHint,
          },
        ]}
        type="radio"
        label={t("pages.claimsLanguage.sectionLabel")}
        hint={t("pages.claimsLanguage.sectionLabelHint")}
      />
    </QuestionPage>
  );
};

export default withBenefitsApplication(Language);
