import { formatDatePeriod, formatDateRange } from "src/utils/formatDateRange";

import ApplicationSplit from "src/models/ApplicationSplit";
import React from "react";
import { Trans } from "react-i18next";
import calculateWaitingPeriod from "src/utils/calculateWaitingPeriod";
import { computedNextBenefitYear } from "src/models/BenefitYear";

interface LeaveDatesInEachBenefitYearProps {
  applicationSplit: ApplicationSplit;
  showWaitingDayPeriod?: boolean;
}

function LeaveDatesInEachBenefitYear(props: LeaveDatesInEachBenefitYearProps) {
  const currentBYTranslationKey = props.showWaitingDayPeriod
    ? "components.leaveDatesInEachBenefitYear.currentBenefitYearWithWaitingDayPeriod"
    : "components.leaveDatesInEachBenefitYear.currentBenefitYear";

  const newBYTranslationKey = props.showWaitingDayPeriod
    ? "components.leaveDatesInEachBenefitYear.newBenefitYearWithWaitingDayPeriod"
    : "components.leaveDatesInEachBenefitYear.newBenefitYear";

  const newBY = computedNextBenefitYear(
    props.applicationSplit.application_dates_in_benefit_year.end_date
  );

  const newBYStartDate = newBY?.benefit_year_start_date || null;
  const newBYEndDate = newBY?.benefit_year_end_date || null;

  return (
    <React.Fragment>
      <React.Fragment>
        <Trans
          i18nKey={currentBYTranslationKey}
          components={{
            b: <b />,
            ul: <ul className="usa-list" />,
            li: <li />,
            benefitYearDates: formatDateRange(
              props.applicationSplit.crossed_benefit_year
                .benefit_year_start_date,
              props.applicationSplit.crossed_benefit_year.benefit_year_end_date,
              true
            ),
            leaveDates: formatDateRange(
              props.applicationSplit.application_dates_in_benefit_year
                .start_date,
              props.applicationSplit.application_dates_in_benefit_year.end_date,
              true
            ),
            waitingPeriodDates: formatDatePeriod(
              calculateWaitingPeriod(
                props.applicationSplit.application_dates_in_benefit_year
                  .start_date
              ),
              true
            ),
          }}
        />
        <Trans
          i18nKey={newBYTranslationKey}
          components={{
            b: <b />,
            ul: <ul className="usa-list" />,
            li: <li />,
            benefitYearDates: formatDateRange(
              newBYStartDate,
              newBYEndDate,
              true
            ),
            leaveDates: formatDateRange(
              props.applicationSplit.application_dates_outside_benefit_year
                .start_date,
              props.applicationSplit.application_dates_outside_benefit_year
                .end_date,
              true
            ),
            waitingPeriodDates: formatDatePeriod(
              calculateWaitingPeriod(
                props.applicationSplit.application_dates_outside_benefit_year
                  .start_date
              ),
              true
            ),
          }}
        />
      </React.Fragment>
    </React.Fragment>
  );
}

export default LeaveDatesInEachBenefitYear;
