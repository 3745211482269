// Build an address string using address elements
function formatAddress(
  line_1: string,
  line_2: string | undefined,
  city: string,
  state: string,
  zip: string
): string {
  return `${line_1}${line_2 ? `, ${line_2}` : ""}, ${city}, ${state} ${zip}`;
}

export default formatAddress;
