/* Enums for localize lanugage preference options
 * @enum {string}
 */
const LocalizeLanguage = {
  english: "en",
  chinese_simplified: "zh-Hans",
  haitian_creole: "ht",
  portuguese: "pt-BR",
  spanish: "es",
  vietnamese: "vi",
  french: "fr",
} as const;

export default LocalizeLanguage;
