import withAppeal, { WithAppealProps } from "../../../hoc/withAppeal";

import Appeal from "../../../models/Appeal";
import ConditionalContent from "../../../components/ConditionalContent";
import InputChoiceGroup from "../../../components/core/InputChoiceGroup";
import QuestionPage from "../../../components/QuestionPage";
import React from "react";
import Textarea from "../../../components/core/Textarea";
import useFormState from "../../../hooks/useFormState";
import useFunctionalInputProps from "../../../hooks/useFunctionalInputProps";
import { useTranslation } from "react-i18next";

export const fields: Array<keyof Appeal> = [
  "had_preventing_circumstances",
  "originally_decided_at_reason_for_past_due",
];

export const NoticeCircumstances = (props: WithAppealProps) => {
  const { appLogic, appeal } = props;
  const { t } = useTranslation();

  const { formState, updateFields, clearField, getField } =
    useFormState(appeal);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const handleSave = async () =>
    await appLogic.appeals.update(appeal.appeal_id, formState);

  return (
    <QuestionPage
      onSave={handleSave}
      title={t("pages.appealsCircumstances.title")}
    >
      <InputChoiceGroup
        choices={[
          {
            checked: formState.had_preventing_circumstances === true,
            label: t("pages.appealsCircumstances.choiceYes"),
            value: "true",
          },
          {
            checked: formState.had_preventing_circumstances === false,
            label: t("pages.appealsCircumstances.choiceNo"),
            value: "false",
          },
        ]}
        label={t("pages.appealsCircumstances.hadCircumstancesLabel")}
        type="radio"
        {...getFunctionalInputProps("had_preventing_circumstances")}
      />
      <ConditionalContent
        visible={formState.had_preventing_circumstances === true}
        fieldNamesClearedWhenHidden={[
          "originally_decided_at_reason_for_past_due",
        ]}
        clearField={clearField}
        getField={getField}
        updateFields={updateFields}
      >
        <Textarea
          label={t("pages.appealsCircumstances.reasonLabel")}
          hint={t("pages.appealsCircumstances.reasonHint")}
          {...getFunctionalInputProps(
            "originally_decided_at_reason_for_past_due"
          )}
        />
      </ConditionalContent>
    </QuestionPage>
  );
};

export default withAppeal(NoticeCircumstances);
