import { Trans, useTranslation } from "react-i18next";
import { cloneDeep, get } from "lodash";
import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "../../hoc/withBenefitsApplication";

import Alert from "../../components/core/Alert";
import ConditionalContent from "../../components/ConditionalContent";
import Heading from "../../components/core/Heading";
import InputChoiceGroup from "../../components/core/InputChoiceGroup";
import PreviousLeave from "../../models/PreviousLeave";
import PreviousLeaveDetailsCard from "../../components/PreviousLeaveDetailsCard";
import QuestionPage from "../../components/QuestionPage";
import React from "react";
import RepeatableFieldset from "../../components/core/RepeatableFieldset";
import formatDate from "../../utils/formatDate";
import useFormState from "../../hooks/useFormState";
import useFunctionalInputProps from "../../hooks/useFunctionalInputProps";

export const fields = [
  "claim.has_previous_leaves",
  "claim.previous_leaves[*].is_continuous",
  "claim.previous_leaves[*].is_for_current_employer",
  "claim.previous_leaves[*].leave_reason",
  "claim.previous_leaves[*].worked_per_week_minutes",
  "claim.previous_leaves[*].leave_minutes",
  "claim.previous_leaves[*].leave_start_date",
  "claim.previous_leaves[*].leave_end_date",
];

/**
 * Renders the Previous Leaves page
 */
export const PreviousLeaves = (props: WithBenefitsApplicationProps) => {
  const { t } = useTranslation();
  const { appLogic, claim } = props;

  /**
   * Get the initial state of the has_previous_leaves data.
   * Before this page was introduced, we collected previous leaves data from claimants
   * on the "previous-leaves-same-reason" and "previous-leaves-other-reason" pages.
   * If claimants have already answered questions on those pages, use that data to
   * fill out the data on this page.
   */
  const getHasPreviousLeavesInitialState = () => {
    if (
      claim.has_previous_leaves !== undefined &&
      claim.has_previous_leaves !== null
    ) {
      // claimant has made a selection for has_previous_leaves - use that!
      return claim.has_previous_leaves;
    }

    if (
      claim.has_previous_leaves_same_reason === true ||
      claim.has_previous_leaves_other_reason === true
    ) {
      // claimant has already indicated a previous leave (via the original fields)
      return true;
    }

    if (
      claim.has_previous_leaves_same_reason === false &&
      claim.has_previous_leaves_other_reason === false
    ) {
      // claimant has already indicated no previous leaves (via the original fields)
      return false;
    }

    // claimant has not made a selection for previous leaves at any time
    return null;
  };

  /**
   * Get the initial state of the data that drives the UI components.
   */
  const getInitialState = () => {
    const has_previous_leaves = getHasPreviousLeavesInitialState();
    const previous_leaves = claim.previous_leaves.map((leave) => {
      if (
        leave.is_continuous == null &&
        (leave.leave_minutes != null || leave.worked_per_week_minutes != null)
      ) {
        leave.is_continuous = false;
      }

      return leave;
    });

    return {
      has_previous_leaves,
      previous_leaves,
    };
  };

  const initialState = getInitialState();
  const { formState, updateFields } = useFormState(initialState);

  /**
   * Set an initial previous_leaves if the value has changed to `true` and the
   * array is empty.
   */
  if (formState.has_previous_leaves && formState.previous_leaves.length === 0) {
    formState.previous_leaves = [
      new PreviousLeave({
        type: "any_reason",
      }),
    ];
  }

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const addNewPreviousLeave = () => {
    const previousLeavesCopy = cloneDeep(previous_leaves);

    updateFields({
      previous_leaves: [
        ...previousLeavesCopy,
        new PreviousLeave({
          type: "any_reason",
        }),
      ],
    });
  };

  const removePreviousLeave = (_entry: PreviousLeave, index: number) => {
    const previousLeavesCopy = [...previous_leaves];
    previousLeavesCopy.splice(index, 1);
    updateFields({ previous_leaves: previousLeavesCopy });
  };

  const renderNewPreviousLeaveDetailsCard = (
    entry: PreviousLeave,
    index: number
  ) => {
    return (
      <PreviousLeaveDetailsCard
        previousLeave={entry}
        index={index}
        currentEmployerFein={claim.employer_fein || ""}
        getFunctionalInputProps={getFunctionalInputProps}
      />
    );
  };

  const handleSave = () => {
    const patchData = { ...formState };

    if (formState.has_previous_leaves === false) {
      patchData.previous_leaves = [];
    }

    return appLogic.benefitsApplications.update(
      claim.application_id,
      patchData
    );
  };

  const previous_leaves = get(formState, "previous_leaves");
  const limit = 12;

  const previousLeaveStartDate = formatDate(
    claim.computed_start_dates.same_reason
  ).full();

  // "Did you take any other paid or unpaid leaves (like FMLA) between"...
  const getHasPreviousLeavesQuestion = () => {
    const getAlert = () => {
      const showPreviousDates = claim.previous_pfml_leave_periods?.length !== 0;

      if (!showPreviousDates) {
        return null;
      }

      return (
        <Alert
          className="text-normal"
          data-test="info-alert"
          noIcon
          state="info"
        >
          <Trans
            i18nKey="pages.claimsPreviousLeaves.alertPreviousLeaveDates"
            components={{
              div: <div />,
              "previous-pfml-dates": (
                <React.Fragment>
                  {claim.previous_pfml_leave_periods.map(
                    (previousPfmlLeave, i) =>
                      previousPfmlLeave.start_date !== null &&
                      previousPfmlLeave.end_date !== null ? (
                        <div key={i}>
                          <span>
                            <Trans
                              i18nKey="pages.claimsPreviousLeaves.previousPfmlPeriodListItem"
                              values={{
                                start_date: formatDate(
                                  previousPfmlLeave.start_date
                                ).full(),
                                end_date: formatDate(
                                  previousPfmlLeave.end_date
                                ).full(),
                              }}
                            />
                          </span>
                        </div>
                      ) : null
                  )}
                </React.Fragment>
              ),
            }}
          />
        </Alert>
      );
    };

    const getHeadingLabel = () => (
      <React.Fragment>
        <Heading level="2" size="1">
          <Trans
            i18nKey="pages.claimsPreviousLeaves.questionHasPreviousLeavesLabel"
            values={{
              previousLeaveStartDate,
            }}
          />
        </Heading>
        {getAlert()}
      </React.Fragment>
    );

    const getChoices = () => {
      return [
        {
          checked: formState.has_previous_leaves === true,
          hint: (
            <Trans
              i18nKey="pages.claimsPreviousLeaves.questionHasPreviousLeavesChoiceYesHint"
              values={{
                previousLeaveStartDate,
              }}
            />
          ),
          label: t("pages.claimsPreviousLeaves.choiceYes"),
          value: "true",
        },
        {
          checked: formState.has_previous_leaves === false,
          hint: (
            <Trans
              i18nKey="pages.claimsPreviousLeaves.questionHasPreviousLeavesChoiceNoHint"
              values={{
                previousLeaveStartDate,
              }}
            />
          ),
          label: t("pages.claimsPreviousLeaves.choiceNo"),
          value: "false",
        },
      ];
    };

    return (
      <InputChoiceGroup
        {...getFunctionalInputProps("has_previous_leaves")}
        choices={getChoices()}
        label={getHeadingLabel()}
        type="radio"
      />
    );
  };

  /**
   * Displays the component allowing claimant to add and remove leaves,
   * and display a PreviousLeaveDetailsCard with additional questions.
   * Only displayed if the user selects "Yes" for the has_previous_leaves question.
   */
  const getPreviousLeavesDetailsSection = () => {
    return (
      <ConditionalContent visible={formState.has_previous_leaves === true}>
        <RepeatableFieldset
          addButtonLabel={t(
            "pages.claimsPreviousLeaves.previousLeavesAddButton"
          )}
          entries={previous_leaves}
          headingPrefix={t(
            "pages.claimsPreviousLeaves.previousLeavesEntryPrefix"
          )}
          onAddClick={addNewPreviousLeave}
          onRemoveClick={removePreviousLeave}
          removeButtonLabel={t(
            "pages.claimsPreviousLeaves.previousLeavesRemoveButton"
          )}
          render={renderNewPreviousLeaveDetailsCard}
          limit={limit}
          limitMessage={
            <div title="limit message">
              <Trans
                i18nKey="pages.claimsPreviousLeaves.previousLeavesLimitMessage"
                values={{ limit }}
              />
            </div>
          }
        />
      </ConditionalContent>
    );
  };

  // TODO(PFMLPB-11390): Show an alert with previous PFML dates

  return (
    <QuestionPage
      title={t("pages.claimsPreviousLeaves.title")}
      onSave={handleSave}
    >
      {getHasPreviousLeavesQuestion()}

      <br />
      {getPreviousLeavesDetailsSection()}
    </QuestionPage>
  );
};

export default withBenefitsApplication(PreviousLeaves);
