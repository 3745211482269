import React, { useEffect } from "react";
import {
  filterByApplication,
  getClaimantLegalNotices,
} from "../../../models/Document";
import withAppeal, { WithAppealProps } from "../../../hoc/withAppeal";
import withClaimDetail, {
  WithClaimDetailProps,
} from "../../../hoc/withClaimDetail";

import Appeal from "../../../models/Appeal";
import ConditionalContent from "../../../components/ConditionalContent";
import InputChoiceGroup from "../../../components/core/InputChoiceGroup";
import LegalNoticeList from "../../../components/LegalNoticeList";
import QuestionPage from "../../../components/QuestionPage";
import Textarea from "../../../components/core/Textarea";
import useFormState from "../../../hooks/useFormState";
import useFunctionalInputProps from "../../../hooks/useFunctionalInputProps";
import { useTranslation } from "react-i18next";

export const fields: Array<keyof Appeal> = [
  "has_read_notices",
  "reason_for_not_reading_notices",
];

export const NoticeRead = (props: WithAppealProps & WithClaimDetailProps) => {
  const { appLogic, appeal, claim_detail } = props;
  const { t } = useTranslation();

  useEffect(() => {
    appLogic.documents.loadAll(claim_detail.application_id);
  }, [appLogic.documents, claim_detail.application_id]);

  const { formState, updateFields, clearField, getField } =
    useFormState(appeal);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const handleSave = async () => {
    await appLogic.appeals.update(appeal.appeal_id, formState);
  };

  const documentsForApplication = filterByApplication(
    appLogic.documents.documents.items,
    claim_detail.application_id
  );
  const legalNotices = getClaimantLegalNotices(documentsForApplication);

  return (
    <QuestionPage
      onSave={handleSave}
      title={t("pages.appealsNoticeRead.title")}
    >
      <InputChoiceGroup
        choices={[
          {
            checked: formState.has_read_notices === true,
            label: t("pages.appealsNoticeRead.choiceYes"),
            value: "true",
          },
          {
            checked: formState.has_read_notices === false,
            label: t("pages.appealsNoticeRead.choiceNo"),
            value: "false",
          },
        ]}
        label={t("pages.appealsNoticeRead.hasReadLabel")}
        type="radio"
        hint={
          legalNotices.length ? (
            <LegalNoticeList
              documents={legalNotices}
              onDownloadClick={props.appLogic.documents.download}
            />
          ) : null
        }
        {...getFunctionalInputProps("has_read_notices")}
      />
      <ConditionalContent
        visible={formState.has_read_notices === false}
        fieldNamesClearedWhenHidden={["reason_for_not_reading_notices"]}
        clearField={clearField}
        getField={getField}
        updateFields={updateFields}
      >
        <Textarea
          label={t("pages.appealsNoticeRead.reasonLabel")}
          {...getFunctionalInputProps("reason_for_not_reading_notices")}
        />
      </ConditionalContent>
    </QuestionPage>
  );
};

export default withClaimDetail(withAppeal(NoticeRead));
