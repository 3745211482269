import withChangeRequests, {
  WithChangeRequestsProps,
} from "./withChangeRequests";

import ChangeRequest from "../models/ChangeRequest";
import PageNotFound from "../components/PageNotFound";
import React from "react";

export interface WithChangeRequestProps
  extends Omit<WithChangeRequestsProps, "query"> {
  change_request: ChangeRequest;
}

interface QueryForWithChangeRequest {
  query: {
    change_request_id?: string;
  };
}

function _getChangeRequest(
  props: WithChangeRequestsProps & QueryForWithChangeRequest
) {
  const change_request_id = props.query.change_request_id;
  return props.change_requests.getItem(change_request_id || "");
}

function withChangeRequest<T extends WithChangeRequestProps>(
  Component: React.ComponentType<T>
) {
  const ComponentWithChangeRequest = (
    props: WithChangeRequestsProps & QueryForWithChangeRequest
  ) => {
    const changeRequest = _getChangeRequest(props);

    if (!changeRequest) {
      return <PageNotFound />;
    }

    return (
      <Component
        {...(props as T & QueryForWithChangeRequest)}
        change_request={changeRequest}
      />
    );
  };
  return withChangeRequests(ComponentWithChangeRequest);
}

export interface WithOptionalChangeRequestProps
  extends Omit<WithChangeRequestsProps, "query"> {
  change_request?: ChangeRequest;
}

export function withOptionalChangeRequest<
  T extends WithOptionalChangeRequestProps
>(Component: React.ComponentType<T>) {
  const ComponentWithOptionalChangeRequest = (
    props: WithChangeRequestsProps & QueryForWithChangeRequest
  ) => {
    const changeRequest = _getChangeRequest(props);

    return (
      <Component
        {...(props as T & QueryForWithChangeRequest)}
        change_request={changeRequest}
      />
    );
  };
  return withChangeRequests(ComponentWithOptionalChangeRequest);
}

export default withChangeRequest;
