import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import MayflowerIcon from "./MayflowerIcon";
import classnames from "classnames";
import logo from "@massds/mayflower-assets/static/images/logo/stateseal.png";

/**
 * A thin banner attached to the top of all pages of the site for branding and
 * assurance purposes. It assures visitors that they are using an official and
 * secure government website.
 *
 * Requires Mayflower styles used in app.scss:
 * - "~@massds/mayflower-assets/scss/01-atoms/button-with-icon"
 * - "~@massds/mayflower-assets/scss/02-molecules/brand-banner"
 *
 * Behavior ported from "@massds/mayflower-assets@11.24.0/js/brand-banner.js"
 */
const BrandBanner = () => {
  const { t } = useTranslation();

  const iconProps = {
    fill: "currentColor",
    height: "30",
    width: "30",
  };
  const iconPropsSmall = {
    fill: "currentColor",
    height: "12",
    width: "12",
  };
  const iconPropsChevron = {
    fill: "currentColor",
    height: "1em",
    width: "1em",
  };

  const [isExpanded, setExpanded] = useState(false);
  const handleClick = () => {
    setExpanded(!isExpanded);
  };
  const brandBannerExpansionClasses = classnames("ma__brand-banner-expansion", {
    "ma__brand-banner-expansion--expanded": isExpanded,
  });

  return (
    <div
      className="ma__brand-banner ma__brand-banner--c-primary-bg-light"
      data-testid="mayflower_brand_banner"
    >
      <button
        className="ma__brand-banner-container"
        aria-controls="ma__brand-banner-content"
        aria-expanded={isExpanded}
        onClick={handleClick}
        type="button"
      >
        <img
          className="ma__brand-banner-logo"
          src={logo}
          alt={t("components.siteLogo.sealAlt")}
        />
        <span className="ma__brand-banner-text">
          <span>{t("components.brandBanner.buttonMessage")}</span>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <span className="ma__brand-banner-button ma__button-icon ma__icon-small ma__button-icon--quaternary ma__button-icon--c-primary">
            <span>{t("components.brandBanner.buttonCallToAction")}</span>
            <MayflowerIcon name="chevron" {...iconPropsChevron} />
          </span>
        </span>
      </button>
      <ul className={brandBannerExpansionClasses} id="ma__brand-banner-content">
        <li className="ma__brand-banner-expansion-item">
          <span className="text-primary">
            <MayflowerIcon name="building" {...iconProps} />
          </span>
          <div className="ma__brand-banner-expansion-item-content">
            <Trans i18nKey="components.brandBanner.officialMessage" />
          </div>
        </li>
        <li className="ma__brand-banner-expansion-item">
          <span className="text-secondary">
            <MayflowerIcon name="lock" {...iconProps} />
          </span>
          <div className="ma__brand-banner-expansion-item-content">
            <Trans
              i18nKey="components.brandBanner.securityMessage"
              components={{
                icon: <MayflowerIcon name="lock" {...iconPropsSmall} />,
              }}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default BrandBanner;
