/* eslint sort-keys: ["error", "asc"] */
/**
 * @file Document (AKA File) model and enum values
 */
import LeaveReason from "./LeaveReason";
import { ValuesOf } from "../../types/common";
import dayjs from "dayjs";
import tracker from "src/services/tracker";

export const CertificationType = {
  certificationForm: "Certification Form",
  [LeaveReason.activeDutyFamily]: "Military exigency form",
  [LeaveReason.care]: "Care for a family member form",
  [LeaveReason.bonding]: "Child bonding evidence form",
  [LeaveReason.medical]: "Own serious health condition form",
  [LeaveReason.pregnancy]: "Pregnancy/Maternity form",
  [LeaveReason.serviceMemberFamily]: "Care for a family member form",
  coveredServiceMemberProof: "Covered Service Member Identification Proof",
  familyMemberActiveDutyServiceProof: "Family Member Active Duty Service Proof",
  healthcareProviderForm: "Healthcare Provider Form",
  medicalCertification: "State managed Paid Leave Confirmation", // TODO (CP-2029): Remove this legacy type once claims filed before 7/1/2021 are adjudicated
} as const;

export type CertificationFormTypeEnum =
  typeof CertificationType.certificationForm;

export const OtherDocumentType = {
  appealAcknowledgment: "Appeal Acknowledgment",
  appealApproved: "Appeal Approved",
  appealDismissedExempt: "Appeal Dismissed - Exempt Employer",
  appealDismissedOther: "Appeal Dismissed - Other",
  appealHearingVirtualFillable: "Appeal Hearing Virtual Fillable",
  appealModifyDecision: "Modify Decision",
  appealPostponementAgency: "Appeal Postponement Agency",
  appealPostponementApproved: "Appeal Postponement Approved",
  appealPostponementDenied: "Appeal Postponement Denied",
  appealRFI: "Appeal RFI",
  appealReinstatementDenied: "Appeal Reinstatement Denied",
  appealReinstatementGranted: "Appeal Reinstatement Granted",
  appealReturnedToAdjudication: "Appeal - Returned to Adjudication",
  appealWithdrawn: "Appeal Withdrawn",
  appealsSupportingDocumentation: "Appeals Supporting Documentation",
  approvalNotice: "Approval Notice",
  approvalNoticeExplanationOfWages: "Approval Notice Explanation of Wages",
  approvalOfApplicationChange: "Approval of Application Change",
  approvedLeaveDatesCancelled: "Approved Leave Dates Cancelled",
  approvedTimeCancelled: "Approved Time Cancelled",
  benefitAmountChangeNotice: "Benefit Amount Change Notice",
  changeRequestApproved: "Change Request Approved",
  changeRequestDenied: "Change Request Denied",
  denialNotice: "Denial Notice",
  denialNoticeExplanationOfWages: "Denial Notice Explanation of Wages",
  denialOfApplication: "Denial of Application",
  denialOfApplicationChange: "Denial of Application Change",
  dismissalForFailureToAttendHearing: "Dismissal for Failure to Attend Hearing",
  eftChangeRequest: "EFT Change Request",
  explanationOfWages: "Explanation of Wages",
  identityVerification: "Identification Proof",
  intermittentTimeApprovalNotice: "Intermittent Time Approved Notice",
  intermittentTimeReported: "Intermittent Time Reported",
  leaveAllotmentChangeNotice: "Leave Allotment Change Notice",
  maximumWeeklyBenefitChangeNotice: "Maximum Weekly Benefit Change Notice",
  medicalCertification: "State managed Paid Leave Confirmation",
  noticeOfChildSupportWithholding: "Notice of Child Support Withholding",
  noticeOfDefault: "Notice of Default",
  overpaymentFullBalanceDemand: "Overpayment Notice-Full Balance Demand",
  overpaymentFullBalanceRecovery: "OP- Full Balance Recovery",
  overpaymentFullBalanceRecoveryManual: "OP- Full Balance Recovery - Manual",
  overpaymentFullDemandErBenefits: "Overpayment Full Demand ER Benefits",
  overpaymentFullDemandIntermittent: "Overpayment Full Demand Intermittent",
  overpaymentFullDemandLeaveChange: "Overpayment Full Demand Leave Change",
  overpaymentFullDemandPaidTimeOff: "Overpayment Full Demand Paid Time Off",
  overpaymentFullDemandUi: "Overpayment Full Demand UI",
  overpaymentFullDemandWorkersComp: "Overpayment Full Demand Workers Comp",
  overpaymentFullRecoveryErBenefits: "Overpayment Full Recovery ER Benefits",
  overpaymentFullRecoveryIntermittent: "Overpayment Full Recovery Intermittent",
  overpaymentFullRecoveryLeaveChange: "Overpayment Full Recovery Leave Change",
  overpaymentFullRecoveryPaidTime_off:
    "Overpayment Full Recovery Paid Time Off",
  overpaymentFullRecoveryUi: "Overpayment Full Recovery UI",
  overpaymentFullRecoveryWorkersComp: "Overpayment Full Recovery Workers Comp",
  overpaymentPartialBalance: "OP- Partial Recovery and Remaining Bal",
  overpaymentPartialDemandErBenefits: "Overpayment Partial Demand ER Benefits",
  overpaymentPartialDemandIntermittent:
    "Overpayment Partial Demand Intermittent",
  overpaymentPartialDemandUi: "Overpayment Partial Demand UI",
  overpaymentPartialDemandWorkersComp:
    "Overpayment Partial Demand Workers Comp",
  overpaymentPartialLeaveChange: "Overpayment Partial Leave Change",
  overpaymentPartialPaidTimeOff: "Overpayment Partial Paid Time Off",
  overpaymentPaymentReceivedNewBalance:
    "Overpayment Payment Received New Balance",
  overpaymentPayoff: "Overpayment Payoff",
  overpaymentPayoffNotice: "Overpayment Payoff Notice",
  paymentReceivedUpdatedOverpaymentBalance:
    "Payment Received-Updated Overpayment Balance",
  requestForInfoNotice: "Request for more Information",
  taxForm1099: "1099G Tax Form for Claimants",
  w9TaxForm: "W9 Tax Form",
  withdrawalNotice: "Pending Application Withdrawn",
} as const;

export const MilitaryDocumentType = {
  activeDutyFamily: CertificationType["Military Exigency Family"],
  coveredServiceMemberProof: CertificationType.coveredServiceMemberProof,
  familyMemberActiveDutyServiceProof:
    CertificationType.familyMemberActiveDutyServiceProof,
  serviceMemberFamily: CertificationType["Military Caregiver"],
} as const;

export type MilitaryDocumentTypeEnum = ValuesOf<typeof MilitaryDocumentType>;

export type DocumentTypeEnum =
  | ValuesOf<typeof CertificationType>
  | ValuesOf<typeof OtherDocumentType>
  | ValuesOf<typeof MilitaryDocumentType>;

/**
 * Enums for Document `document_type` field.  In the `certification` object, `certificationForm` is only used for uploads of certification forms; the API then sets the plan
 * proof based on leave reason.  The other `certification` types are used when we retrieve documents from FINEOS.
 * @enum {string}
 */
export const DocumentType = {
  certification: { ...CertificationType },
  ...OtherDocumentType,
} as const;

/**
 * A document record from the application endpoints
 */
export interface BenefitsApplicationDocument {
  content_type: string;
  created_at: string;
  description: string;
  document_type: DocumentTypeEnum;
  fineos_document_id: string;
  name: string;
  user_id: string;
  application_id: string;
  is_legal_notice: boolean;
}

/**
 * A document record from the appeal endpoints
 */
export interface AppealDocument {
  content_type: string;
  created_at: string;
  description: string;
  document_type: DocumentTypeEnum;
  fineos_document_id: string;
  name: string;
  user_id: string;
  appeal_id: string;
  is_legal_notice?: boolean;
}

/**
 * A document record from the employer endpoints
 */
export interface ClaimDocument {
  content_type: string;
  created_at: string;
  description: string | null;
  document_type: DocumentTypeEnum;
  fineos_document_id: string;
  name: string | null;
  is_legal_notice?: boolean;
}

// TODO (PFMLPB-9526): Combine document type models to use Document
/**
 * A document record from the user endpoints
 */
export interface UserDocument {
  content_type: string;
  created_at: string;
  description: string | null;
  document_type: DocumentTypeEnum;
  fineos_document_id: string;
  name: string | null;
  user_id: string;
  is_legal_notice?: boolean;
}

/**
 * Get only documents associated with a given Application
 */
export function filterByApplication(
  items: BenefitsApplicationDocument[],
  application_id: string
) {
  return items.filter((item) => {
    return (
      isBenefitsApplicationDocument(item) &&
      item.application_id === application_id
    );
  });
}

/**
 * Get only documents associated with a given appeal
 */
export function filterByAppeal(
  items: Array<AppealDocument | ClaimDocument>,
  appeal_id: string
) {
  return items.filter((item) => {
    return isAppealDocument(item) && item.appeal_id === appeal_id;
  });
}

/**
 * Only show denial notices for employees
 * if it was created before Denial EOW notice was generated
 * or when there is no denial EOW notice.
 * If the document isn't a denial notice, we show it.
 */
export function canShowDenialNotice<
  T extends
    | BenefitsApplicationDocument
    | ClaimDocument
    | AppealDocument
    | UserDocument
>(documents: T[], document: T): boolean {
  const denialNoticeEOW =
    DocumentType.denialNoticeExplanationOfWages.toLowerCase();

  const lowerCaseDocumentTypes = documents.map((doc) =>
    doc.document_type.toLowerCase()
  );
  const includesDenialEOWNotice =
    lowerCaseDocumentTypes.includes(denialNoticeEOW);

  const denialEOWDate = new Date("2023-07-08");
  const documentCreatedDate = new Date(document.created_at);
  const createdAfterDenialEOWDate =
    dayjs(documentCreatedDate).isAfter(denialEOWDate);

  return !(
    isDenialNotice(document) &&
    includesDenialEOWNotice &&
    createdAfterDenialEOWDate
  );
}

/**
 * Is this document a denial notice that we need to filter?
 * We only filter denialNotice and denialOfApplication documents
 */
export function isDenialNotice(
  document:
    | BenefitsApplicationDocument
    | ClaimDocument
    | AppealDocument
    | UserDocument
) {
  return (
    document.document_type === DocumentType.denialNotice ||
    document.document_type === DocumentType.denialOfApplication
  );
}

/**
 * Get approval notice for employees
 * if "approval notice explanation of wages is available", return false - do not show "Approval Notice"
 */
export function showEmployeeApprovalNotice<
  T extends
    | BenefitsApplicationDocument
    | ClaimDocument
    | AppealDocument
    | UserDocument
>(documents: T[]): boolean {
  const approvalNoticeExplanationOfWages =
    DocumentType.approvalNoticeExplanationOfWages.toLowerCase();

  const lowerCaseDocumentTypes = documents.map((doc) =>
    doc.document_type.toLowerCase()
  );
  const includesApprovalNoticeExplanationOfWages =
    lowerCaseDocumentTypes.includes(approvalNoticeExplanationOfWages);

  // if "Approval Notice Explanation of Wages" is available, do not show the "Approval Notice" document
  return !includesApprovalNoticeExplanationOfWages;
}

export function filterEmployeeApprovalNotices<
  T extends
    | BenefitsApplicationDocument
    | ClaimDocument
    | AppealDocument
    | UserDocument
>(documents: T[]): T[] {
  const keepApprovalNotice = showEmployeeApprovalNotice(documents);
  return documents.filter((document) => {
    return document.document_type.toLowerCase() ===
      DocumentType.approvalNotice.toLowerCase()
      ? keepApprovalNotice
      : true;
  });
}

/**
 * Get only documents associated with a given selection of document_types
 */
export function findDocumentsByTypes<
  T extends
    | BenefitsApplicationDocument
    | ClaimDocument
    | AppealDocument
    | UserDocument
>(documents: T[], document_types: DocumentTypeEnum[]): T[] {
  const lowerCaseDocumentTypes = document_types.map((documentType) =>
    documentType.toLowerCase()
  );

  return documents.filter((document) => {
    // Ignore casing differences by comparing lowercased enums
    if (
      !lowerCaseDocumentTypes.includes(document.document_type.toLowerCase())
    ) {
      return false;
    }
    return canShowDenialNotice(documents, document);
  });
}

export const employerLegalDocTypes = [
  DocumentType.appealAcknowledgment,
  DocumentType.approvalNotice,
  DocumentType.approvalOfApplicationChange,
  DocumentType.denialNotice,
  DocumentType.denialOfApplicationChange,
  DocumentType.requestForInfoNotice,
  DocumentType.withdrawalNotice,
  DocumentType.maximumWeeklyBenefitChangeNotice,
  DocumentType.benefitAmountChangeNotice,
  DocumentType.leaveAllotmentChangeNotice,
  DocumentType.approvedTimeCancelled,
  DocumentType.changeRequestApproved,
  DocumentType.changeRequestDenied,
  DocumentType.overpaymentFullBalanceDemand,
  DocumentType.overpaymentFullBalanceRecovery,
  DocumentType.overpaymentFullBalanceRecoveryManual,
  DocumentType.overpaymentPartialBalance,
  DocumentType.overpaymentPayoffNotice,
  DocumentType.intermittentTimeApprovalNotice,
  DocumentType.appealApproved,
  DocumentType.appealDismissedExempt,
  DocumentType.appealDismissedOther,
  DocumentType.appealHearingVirtualFillable,
  DocumentType.appealModifyDecision,
  DocumentType.appealRFI,
  DocumentType.appealReturnedToAdjudication,
  DocumentType.appealWithdrawn,
  DocumentType.paymentReceivedUpdatedOverpaymentBalance,
  DocumentType.approvedLeaveDatesCancelled,
  DocumentType.denialOfApplication,
  DocumentType.intermittentTimeReported,
];

export const employeeLegalDocTypes = [
  ...employerLegalDocTypes,
  DocumentType.appealPostponementAgency,
  DocumentType.appealPostponementApproved,
  DocumentType.appealPostponementDenied,
  DocumentType.appealReinstatementDenied,
  DocumentType.appealReinstatementGranted,
  DocumentType.denialNoticeExplanationOfWages,
  DocumentType.explanationOfWages,
  DocumentType.approvalNoticeExplanationOfWages,
  DocumentType.overpaymentFullDemandErBenefits,
  DocumentType.overpaymentFullDemandIntermittent,
  DocumentType.overpaymentFullDemandLeaveChange,
  DocumentType.overpaymentFullDemandPaidTimeOff,
  DocumentType.overpaymentFullDemandUi,
  DocumentType.overpaymentFullDemandWorkersComp,
  DocumentType.overpaymentFullRecoveryErBenefits,
  DocumentType.overpaymentFullRecoveryIntermittent,
  DocumentType.overpaymentFullRecoveryLeaveChange,
  DocumentType.overpaymentFullRecoveryPaidTime_off,
  DocumentType.overpaymentFullRecoveryUi,
  DocumentType.overpaymentFullRecoveryWorkersComp,
  DocumentType.overpaymentPartialBalance,
  DocumentType.overpaymentPartialDemandErBenefits,
  DocumentType.overpaymentPartialDemandIntermittent,
  DocumentType.overpaymentPartialDemandUi,
  DocumentType.overpaymentPartialDemandWorkersComp,
  DocumentType.overpaymentPartialLeaveChange,
  DocumentType.overpaymentPartialPaidTimeOff,
  DocumentType.overpaymentPaymentReceivedNewBalance,
  DocumentType.overpaymentPayoff,
  DocumentType.dismissalForFailureToAttendHearing,
  DocumentType.noticeOfDefault,
  DocumentType.eftChangeRequest,
  DocumentType.noticeOfChildSupportWithholding,
  DocumentType.w9TaxForm,
];

/**
 * Get only documents that are legal notices for employers
 * and are associated with a given selection of document_types
 * Add denial notice for employers
 */
export function getEmployerLegalNotices(
  documents: Array<BenefitsApplicationDocument | ClaimDocument>
) {
  const legalNotices = [...employerLegalDocTypes, DocumentType.denialNotice];

  const lowerCaseDocumentTypes = legalNotices.map((documentType) =>
    documentType.toLowerCase()
  );

  return documents.filter((document) => {
    // Ignore casing differences by comparing lowercased enums
    return lowerCaseDocumentTypes.includes(
      document.document_type.toLowerCase()
    );
  });
}

/**
 * Get only documents that are legal notices for employees
 */
export function getClaimantLegalNotices(
  documents: BenefitsApplicationDocument[]
) {
  const employeeLegalDocuments = documents.filter(
    (document) =>
      document.is_legal_notice && canShowDenialNotice(documents, document)
  );
  return filterEmployeeApprovalNotices(employeeLegalDocuments);
}

/**
 * Get only documents that are decision notices.
 */
export function getDecisionNotices(
  documents: Array<BenefitsApplicationDocument | ClaimDocument>
) {
  const decisionNotices = [
    DocumentType.approvalNotice,
    DocumentType.denialNotice,
    DocumentType.withdrawalNotice,
    DocumentType.denialOfApplication,
  ];

  return findDocumentsByTypes(documents, [
    ...decisionNotices,
    DocumentType.denialNoticeExplanationOfWages,
  ]);
}

/**
 * Get the most recent RFI document. The logging below shows that there are quite a few claims. Ticket: https://lwd.atlassian.net/browse/PFMLPB-12371
 * that have multiple RFI documents.
 */
export function getRFIDocument(
  documents: Array<BenefitsApplicationDocument | ClaimDocument>
) {
  const rfiDocuments = findDocumentsByTypes(documents, [
    DocumentType.requestForInfoNotice,
  ]);
  if (rfiDocuments.length === 0) {
    return;
  }
  warnIfMultipleRFIDocuments(rfiDocuments);
  const sortedRfiDocuments = sortNewToOld(rfiDocuments);
  return sortedRfiDocuments[0];
}

function warnIfMultipleRFIDocuments(
  rfiDocuments: Array<BenefitsApplicationDocument | ClaimDocument>
) {
  // Heavy logging so we can tell when the ClaimStatusV2 components are shown for a claim and why
  if (rfiDocuments.length > 1) {
    const loggableDocumentIdArray = rfiDocuments
      .map((document) => document.fineos_document_id)
      .join(", ");
    tracker.trackEvent(
      "Multiple RFI documents found for application (array of fineos_document_ids)",
      { loggableDocumentIdArray }
    );
  }
}

export function documentCreatedAtDatesExlcudingRFI(
  documents: Array<BenefitsApplicationDocument | ClaimDocument>
) {
  const documentsExcludingRFIDocument = documents.filter(
    (document) => document.document_type !== "Request for more Information"
  );
  const createdAtDates = documentsExcludingRFIDocument.map(
    (document) => document.created_at
  );

  return createdAtDates;
}

/**
 * @param documents
 * @returns Document
 * This function returns the date the last document was uploaded. We display this value.
 * Currently we use this when documentsUploadedAfterRFIReceived === true to get the date to display.
 */
export function getLatestDocumentUploadDate(
  documents: Array<BenefitsApplicationDocument | ClaimDocument>
) {
  const sortedDocuments = sortNewToOld(documents);
  const latestCreationDate = sortedDocuments[0].created_at;
  return latestCreationDate;
}

/**
 * @param documents
 * @returns string
 * This function returns the date the last document of the specified type was uploaded. We display this value.
 * Currently we use this for extensions in the CertificatioUploadSection when the actionStatus is 'inProgress'.
 */
export const latestDocumentOfTypeUploadedAt = (
  documents: BenefitsApplicationDocument[],
  documentType: DocumentTypeEnum
) => {
  const certificationDocuments = findDocumentsByTypes(documents, [
    documentType,
  ]);
  if (certificationDocuments.length > 0) {
    const mostRecentlyUploaded = sortNewToOld(certificationDocuments)[0];
    return mostRecentlyUploaded.created_at;
  }
  return null;
};

/**
 * Get only documents that are certification documents for a specific leave.
 * This excludes ID verification docs, which aren't **leave** certification,
 * and also not a doc type we want to surface to leave admins.
 */
export function getLeaveCertificationDocs<
  T extends BenefitsApplicationDocument | ClaimDocument
>(documents: T[]) {
  return findDocumentsByTypes(documents, Object.values(CertificationType));
}

export function isBenefitsApplicationDocument(
  document: BenefitsApplicationDocument | ClaimDocument | UserDocument
): document is BenefitsApplicationDocument {
  return "application_id" in document;
}

export function isAppealDocument(
  document: AppealDocument | ClaimDocument | UserDocument
): document is AppealDocument {
  // We don't include appeal acknowledgement since it is a absence_case and appeal_case level notice.
  // Instead we can grab the appeal acknowledgement from Documents if needed in the future.
  return (
    "appeal_id" in document &&
    document.document_type !== DocumentType.appealAcknowledgment
  );
}

export function isClaimDocument(
  document: BenefitsApplicationDocument | ClaimDocument | UserDocument
): document is ClaimDocument {
  return !isBenefitsApplicationDocument(document);
}

export function sortNewToOld(
  documents: Array<
    BenefitsApplicationDocument | ClaimDocument | UserDocument | AppealDocument
  >
) {
  const clonedDocuments = documents.slice(); // avoids mutating the original array
  return clonedDocuments.sort((a, b) => {
    return a.created_at > b.created_at ? -1 : 1;
  });
}
