import { AppealDocument, DocumentTypeEnum } from "../models/Document";
import { DocumentsUploadError, ValidationError } from "../errors";

import ApiResourceCollection from "../models/ApiResourceCollection";
import Appeal from "../models/Appeal";
import AppealsApi from "../api/AppealsApi";
import DocumentsApi from "../api/DocumentsApi";
import { ErrorsLogic } from "./useErrorsLogic";
import { PortalFlow } from "./usePortalFlow";
import TempFile from "../models/TempFile";
import getRelevantIssues from "../../src/utils/getRelevantIssues";
import useCollectionState from "./useCollectionState";
import { useState } from "react";

const useAppealsLogic = ({
  errorsLogic,
  portalFlow,
}: {
  errorsLogic: ErrorsLogic;
  portalFlow: PortalFlow;
}) => {
  const appealsApi = new AppealsApi();
  const {
    addItem: addAppeal,
    addItems: addAppeals,
    collection: appeals,
    updateItem: updateAppeal,
  } = useCollectionState(new ApiResourceCollection<Appeal>("appeal_id"));

  const {
    collection: appealDocuments,
    addItem: addAppealDocument,
    addItems: addAppealDocuments,
  } = useCollectionState(
    new ApiResourceCollection<AppealDocument>("fineos_document_id")
  );

  const documentsApi = new DocumentsApi();
  const [loadedAppealDocs, setLoadedAppealDocs] = useState<{
    [appeal_id: string]: { isLoading: boolean };
  }>({});

  const [loadedAbsenceCases, setLoadedCases] = useState<{
    [fineos_absence_id: string]: { isLoading: boolean };
  }>({});

  const hasLoadedAppealDocuments = (appeal_id: string) =>
    appeal_id in loadedAppealDocs &&
    loadedAppealDocs[appeal_id].isLoading === false;

  const isLoadingAppealDocuments = (appeal_id: string) =>
    appeal_id in loadedAppealDocs &&
    loadedAppealDocs[appeal_id].isLoading === true;

  const complete = async (appealId: string) => {
    errorsLogic.clearErrors();

    try {
      const { appeal } = await appealsApi.complete(appealId);
      updateAppeal(appeal);
      portalFlow.goToNextPage(
        {},
        {
          appeal_id: appeal.appeal_id,
          // Support routing to a Claim Status page
          absence_id: appeal.fineos_absence_id,
        }
      );
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const confirmDocuments = (appeal: Appeal, fineos_document_ids: string[]) => {
    errorsLogic.clearErrors();
    try {
      appealsApi.confirmDocuments(appeal.appeal_id, fineos_document_ids);
      portalFlow.goToNextPage(
        {},
        {
          uploaded_document_type: "appeals-supporting-documentation",
          appeal_id: appeal.appeal_id,
          absence_id: appeal.fineos_absence_id,
        }
      );
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const create = async (formState: Partial<Appeal>) => {
    errorsLogic.clearErrors();

    try {
      const { appeal } = await appealsApi.create(formState);
      addAppeal(appeal);
      portalFlow.goToNextPage({}, { appeal_id: appeal.appeal_id });
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const load = async (appealId: string) => {
    // Skip API call if we already have the appeal in our local state
    const existingAppeal = appeals.getItem(appealId);
    if (existingAppeal) return;

    errorsLogic.clearErrors();

    try {
      const { appeal } = await appealsApi.get(appealId);
      addAppeal(appeal);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const search = async (fineos_absence_id: string) => {
    if (fineos_absence_id in loadedAbsenceCases) return;

    errorsLogic.clearErrors();
    setLoadedCases({
      ...loadedAbsenceCases,
      [fineos_absence_id]: { isLoading: true },
    });

    try {
      const { appeals } = await appealsApi.search(fineos_absence_id);

      addAppeals(appeals);
    } catch (error) {
      errorsLogic.catchError(error);
    } finally {
      setLoadedCases({
        ...loadedAbsenceCases,
        [fineos_absence_id]: { isLoading: false },
      });
    }
  };

  const update = async (appealId: string, formState: Partial<Appeal>) => {
    errorsLogic.clearErrors();

    try {
      const { appeal, warnings } = await appealsApi.update(appealId, formState);
      updateAppeal(appeal);

      const issues = getRelevantIssues(warnings, [portalFlow.page]);
      if (issues.length) throw new ValidationError(issues);

      portalFlow.goToNextPage(
        { appeal },
        {
          // A few pages (like Review) load claim data
          absence_id: appeal.fineos_absence_id,
          // Majority of pages in the Appeal flow load the Appeal data
          appeal_id: appeal.appeal_id,
        }
      );
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Load all documents for a user's appeal
   * This must be called before documents are available
   */
  const loadAllAppealDocuments = async (appeal_id: string) => {
    // if documents already contains docs for appeal_id, don't load again
    // or if we started making a request to the API to load documents, don't load again
    if (
      hasLoadedAppealDocuments(appeal_id) ||
      isLoadingAppealDocuments(appeal_id)
    )
      return;

    errorsLogic.clearErrors();

    setLoadedAppealDocs((loadingAppealDocuments) => {
      const docs = { ...loadingAppealDocuments };
      docs[appeal_id] = {
        isLoading: true,
      };
      return docs;
    });

    try {
      const { appealDocuments: loadedDocuments } =
        await documentsApi.getAppealDocuments(appeal_id);
      addAppealDocuments(loadedDocuments.items);
      setLoadedAppealDocs((loadingAppealDocuments) => {
        const docs = { ...loadingAppealDocuments };
        docs[appeal_id] = {
          isLoading: false,
        };
        return docs;
      });
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Submit files to the API for an appeal and set errors if any
   */
  const attachAppealDocuments = (
    appeal_id: string,
    filesWithUniqueId: TempFile[],
    documentType: DocumentTypeEnum
  ) => {
    const errorMetadata = { appeal_id };
    const uploadFile = async (fileWithUniqueId: TempFile) => {
      const { document } = await documentsApi.attachAppealDocument(
        appeal_id,
        fileWithUniqueId,
        documentType
      );
      return document;
    };

    return uploadFiles(
      filesWithUniqueId,
      documentType,
      uploadFile,
      errorMetadata
    );
  };

  /**
   * Helper method for uploading files, adding uploaded files to the documents collection, and handling errors.
   * @param uploadFile a function which, given a file, will return a Promise to make the actual file upload API request
   * @param errorMetadata additional data to log with any errors that occur
   */
  const uploadFiles = (
    filesWithUniqueId: TempFile[],
    documentType: DocumentTypeEnum,
    uploadFile: (file: TempFile) => Promise<AppealDocument>,
    errorMetadata: {
      appeal_id?: string;
    }
  ) => {
    errorsLogic.clearErrors();

    if (!filesWithUniqueId.length) {
      errorsLogic.catchError(
        new ValidationError([
          {
            // field and type will be used for forming the internationalized error message
            field: "file", // 'file' is the field name in the API
            message: "Client requires at least one file before sending request",
            type: "required",
            namespace: "documents",
          },
        ])
      );
      return [];
    }

    const uploadPromises = filesWithUniqueId.map(async (fileWithUniqueId) => {
      try {
        const document = await uploadFile(fileWithUniqueId);
        addAppealDocument(document);
        return {
          success: true,
          fineos_document_id: document.fineos_document_id,
        };
      } catch (error) {
        errorsLogic.catchError(
          new DocumentsUploadError(
            fileWithUniqueId.id,
            errorMetadata,
            error instanceof ValidationError && error.issues.length
              ? error.issues[0]
              : null
          )
        );
        return { success: false };
      }
    });
    return uploadPromises;
  };

  return {
    appeals,
    appealDocuments,
    attachAppealDocuments,
    complete,
    confirmDocuments,
    create,
    hasLoadedAppealDocuments,
    isLoadingAppealDocuments,
    load,
    loadedAbsenceCases,
    loadAllAppealDocuments,
    search,
    update,
  };
};

export default useAppealsLogic;
export type AppealsLogic = ReturnType<typeof useAppealsLogic>;
