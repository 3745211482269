/* Enums for claimant lanugage preference options
 * @enum {string}
 */
const Language = {
  english: "English",
  chinese_simplified: "Chinese (simplified)",
  haitian_creole: "Haitian Creole",
  portuguese: "Portuguese",
  spanish: "Spanish",
  vietnamese: "Vietnamese",
  french: "French",
  not_listed: "Language not listed",
} as const;

export interface MMGLanguageLookup {
  [key: string]: string;
}

export type MMGLanguageCode =
  | "en"
  | "es"
  | "fr"
  | "zt-hans"
  | "pt-BR"
  | "ht"
  | "vi";

// MMG uses some slightly different language codes than the portal.
// This is the map of language code variations where needed.
// It is used bi-directionally.
// Key is the MMG language code, value is the Portal language code.
export const MMGToPortalLanguageMap: { [key: string]: MMGLanguageCode } = {
  cy: "ht",
  pt: "pt-BR",
};

export const mmgLanguageLookup: MMGLanguageLookup = {
  en: Language.english,
  es: Language.spanish,
  "zt-hans": Language.chinese_simplified,
  "pt-BR": Language.portuguese,
  fr: Language.french,
  ht: Language.haitian_creole,
  vi: Language.vietnamese,
};

export default Language;
