import BenefitYear from "./BenefitYear";
import { ISO8601Timestamp } from "types/common";
import dayjs from "dayjs";

interface StartEndDates {
  start_date: ISO8601Timestamp;
  end_date: ISO8601Timestamp;
}

interface ApplicationSplit {
  crossed_benefit_year: BenefitYear;
  application_dates_in_benefit_year: StartEndDates;
  application_dates_outside_benefit_year: StartEndDates;
  application_outside_benefit_year_submittable_on: ISO8601Timestamp;
}

/**
 * Check if leave in future benefit year could be reviewed if submitted today
 * based on dates in model.
 */
export function isLeaveInSecondBenefitYearReviewableToday(
  split: ApplicationSplit
): boolean {
  const today = dayjs().format("YYYY-MM-DD");

  return isLeaveInSecondBenefitYearReviewableOn(split, today);
}

/**
 * Check if leave in future benefit year could be reviewed if submitted on given
 * date based on dates in model.
 */
export function isLeaveInSecondBenefitYearReviewableOn(
  split: ApplicationSplit,
  date: string
): boolean {
  const secondLeaveEarliestSubmissionDate =
    split.application_outside_benefit_year_submittable_on;

  if (!secondLeaveEarliestSubmissionDate) {
    return false;
  }

  if (!date) {
    return false;
  }

  const secondCanBeSubmitted = secondLeaveEarliestSubmissionDate <= date;

  return secondCanBeSubmitted;
}

export default ApplicationSplit;
