import { ValuesOf } from "../../types/common";
import { merge } from "lodash";

export const ChangeRequestType = {
  modification: "Modification",
  cancellation: "Cancellation",
  extension: "Extension",
  withdrawal: "Withdrawal",
  medicalToBonding: "Medical To Bonding Transition",
} as const;

export default class ChangeRequest {
  change_request_id: string;
  fineos_absence_id: string;
  change_request_type: ValuesOf<typeof ChangeRequestType> | null = null;
  start_date: string | null = null;
  end_date: string | null = null;
  date_of_birth: string | null = null;
  documents_submitted_at: string | null = null;
  submitted_time: string | null = null;

  constructor(attrs: Partial<ChangeRequest>) {
    merge(this, attrs);
  }

  get hasSubmittedDocs(): boolean {
    return !!this.documents_submitted_at;
  }

  get isWithdrawal(): boolean {
    return this.change_request_type === ChangeRequestType.withdrawal;
  }

  get isMedToBonding(): boolean {
    return this.change_request_type === ChangeRequestType.medicalToBonding;
  }

  get isCancellation(): boolean {
    return this.change_request_type === ChangeRequestType.cancellation;
  }

  get isExtension(): boolean {
    return this.change_request_type === ChangeRequestType.extension;
  }
}
