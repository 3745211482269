import dayjs from "dayjs";

function isDateInFuture(date?: string | null): boolean {
  if (!date) {
    return false;
  }
  const now = dayjs();
  return dayjs(date) > now;
}

export default isDateInFuture;
