import React from "react";

export interface CaptionedImageProps {
  readonly alt: string;
  readonly src: string;
  readonly caption: string;
  readonly className?: string;
}

export default function CaptionedImage(props: CaptionedImageProps) {
  return (
    <figure className={props.className}>
      <img alt={props.alt} src={props.src} />
      <figcaption className="font-body-3xs">{props.caption}</figcaption>
    </figure>
  );
}
