import FormLabel from "./FormLabel";
import React from "react";
import classnames from "classnames";
import isBlank from "../../utils/isBlank";
import useUniqueId from "../../hooks/useUniqueId";

export interface TextareaProps {
  /**
   * Localized error message. Setting this enables the error state styling.
   */
  errorMsg?: React.ReactNode;
  /**
   * Additional classes to include on the containing form group element
   */
  formGroupClassName?: string;
  /**
   * Localized hint text
   */
  hint?: React.ReactNode;
  /**
   * Unique HTML id attribute (created by useUniqueId if null)
   */
  inputId?: string;
  /**
   * Localized field label
   */
  label: React.ReactNode;
  /**
   * Override the label's default text-bold class
   */
  labelClassName?: string;
  /**
   * HTML input `name` attribute
   */
  name: string;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  /**
   * Localized text indicating this field is optional
   */
  optionalText?: React.ReactNode;
  /**
   * Enable the smaller label variant
   */
  smallLabel?: boolean;
  /**
   * Sets the input's `value`. Use this in combination with `onChange`
   * for a controlled component.
   */
  value?: string;
}

/**
 * [Textarea](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea) field. Also renders
 * supporting UI elements like label, hint text, and error message.
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/components/text-input/)
 */
function Textarea(props: TextareaProps) {
  let inputId = useUniqueId("Textarea");
  inputId = props.inputId || inputId;

  const hasError = !isBlank(props.errorMsg);

  const fieldClasses = classnames("usa-textarea maxw-mobile-lg", {
    "usa-input--error": hasError,
  });
  const formGroupClasses = classnames(
    "usa-form-group",
    props.formGroupClassName,
    {
      "usa-form-group--error": hasError,
    }
  );

  return (
    <div className={formGroupClasses}>
      <FormLabel
        errorMsg={props.errorMsg}
        hint={props.hint}
        inputId={inputId}
        optionalText={props.optionalText}
        small={props.smallLabel}
        labelClassName={props.labelClassName}
      >
        {props.label}
      </FormLabel>
      <textarea
        aria-labelledby={`${inputId}_label ${inputId}_hint ${inputId}_error`}
        className={fieldClasses}
        id={inputId}
        name={props.name}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
}

export default Textarea;
