import appeal, { AppealFlowContext, guards as appealGuards } from "./appeal";
/**
 * @file Configuration for building an xstate state machine for routing
 * @see https://xstate.js.org/docs/about/concepts.html#finite-state-machines
 * Each state represents a page in the application, keyed by the
 * page's url route. The CONTINUE transition represents the next page in the
 * the flow.
 * @see docs/portal/development.md
 */
import claimant, {
  ClaimantFlowContext,
  guards as claimantGuards,
} from "./claimant";
import employer, {
  EmployerFlowContext,
  guards as employerGuards,
} from "./employer";

import auth from "./auth";
import routes from "../routes";
import user from "./user";

/**
 * https://xstate.js.org/docs/guides/context.html
 */
export type FlowContext = Partial<
  AppealFlowContext & ClaimantFlowContext & EmployerFlowContext
>;

/**
 * https://xstate.js.org/docs/guides/guards.html
 */
export type GuardFn = (
  context: FlowContext,
  event: { type: string },
  condMeta: {
    cond: unknown;
  }
) => boolean;

export const guards: {
  [guardName: string]: GuardFn;
} = {
  ...appealGuards,
  ...claimantGuards,
  ...employerGuards,
};

export default {
  id: "portal-flow",
  initial: routes.applications.index,
  predictableActionArguments: true,
  states: {
    ...appeal.states,
    ...auth.states,
    ...claimant.states,
    ...employer.states,
    ...user.states,
  },
  context: {} as FlowContext,
};
