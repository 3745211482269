/**
 * Takes in data and page size then returns a map of each page with its data
 * @param sortedItems an array of the data items sorted in desired order.
 * @param pageSize the number of items to display per page.
 * @returns a map of pages and each pages data.
 */
export function getMapOfPagesWithData(
  sortedData: React.JSX.Element[],
  pageSize: number
) {
  const totalPages = Math.ceil(sortedData.length / pageSize);
  // Map data array into the corresponding page offset,
  // allow us to retrieve the data by page offset
  // e.g. {1: data[0-9], 2: data[10-19]}
  const mapOfPagesWithData = new Map<number, React.JSX.Element[]>();
  const pages = Array.from(Array(totalPages), (_, index) => index + 1);
  pages.forEach((page) => {
    const firstPageEntryIndex = (page - 1) * pageSize;
    const lastPageEntryIndex = (page - 1) * pageSize + pageSize;
    mapOfPagesWithData.set(
      page,
      sortedData.slice(firstPageEntryIndex, lastPageEntryIndex)
    );
  });
  return mapOfPagesWithData;
}
