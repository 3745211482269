import withAppeal, { WithAppealProps } from "../../../hoc/withAppeal";

import Heading from "../../../components/core/Heading";
import Hint from "../../../components/core/Hint";
import InputText from "../../../components/core/InputText";
import QuestionPage from "../../../components/QuestionPage";
import React from "react";
import useFormState from "../../../hooks/useFormState";
import useFunctionalInputProps from "../../../hooks/useFunctionalInputProps";
import { useTranslation } from "react-i18next";

export const fields = [
  "appeal_phone_number.phone_number",
  "appeal_phone_number",
];

export const Phone = (props: WithAppealProps) => {
  const { appLogic, appeal } = props;
  const { t } = useTranslation();

  const { formState, updateFields } = useFormState(appeal);
  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const handleSave = async () =>
    await appLogic.appeals.update(appeal.appeal_id, formState);

  return (
    <QuestionPage onSave={handleSave} title={t("pages.appealsPhone.title")}>
      <Heading level="2" size="1">
        {t("pages.appealsPhone.header")}
      </Heading>
      <Hint>{t("pages.appealsPhone.phoneHint")}</Hint>
      <InputText
        label={t("pages.appealsPhone.phoneLabel")}
        mask="phone"
        width="medium"
        smallLabel
        {...getFunctionalInputProps("appeal_phone_number.phone_number")}
      />
    </QuestionPage>
  );
};

export default withAppeal(Phone);
