/**
 * @file Custom Error classes. Useful as a way to see all potential errors that our system may throw/catch
 */

export interface CognitoError {
  code: string;
  name: string;
  message: string;
}

// Validation or server error we want to communicate to the user.
export interface Issue {
  field?: string;
  // Technical message intended for debugging purposes, but
  // can be used as a last resort if no other message is available.
  message?: string;
  // Typically the API resource:
  namespace: string;
  rule?: string;
  type?: string;
  // Dynamic values that provide more context for the error. For instance,
  // if this is a "file too large" error, this might include properties
  // for the size limit and the name of the failing file.
  extra?: { [key: string]: string | number };
}

export interface ErrorWithIssues extends BasePortalError {
  issues?: Issue[];
}

export class BasePortalError extends Error {
  constructor(message?: string) {
    super(message);

    // Maintains proper stack trace for where our error was thrown in modern browsers
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, BasePortalError);
    }
  }
}

/**
 * The authenticated user's session expired or couldn't be found.
 */
export class AuthSessionMissingError extends BasePortalError {
  constructor(message?: string) {
    super(message);
    this.name = "AuthSessionMissingError";
  }
}

/**
 * A fetch request failed due to a network error. The error wasn't the fault of the user,
 * and an issue was encountered while setting up or sending a request, or parsing the response.
 * Examples of a NetworkError could be the user's device lost internet connection, a CORS issue,
 * or a malformed request.
 */
export class NetworkError extends BasePortalError {
  constructor(message?: string) {
    super(message);
    this.name = "NetworkError";
  }
}

/**
 * An API response returned a status code greater than 400
 */
export class ApiRequestError extends BasePortalError {
  responseData?: unknown;

  constructor(responseData?: unknown, message?: string) {
    super(message);
    this.responseData = responseData;
    this.name = "ApiRequestError";
  }
}

/**
 * A fetch request failed due to a 404 error
 */
export class NotFoundError extends ApiRequestError {
  constructor(responseData?: unknown, message?: string) {
    super(responseData, message);
    this.name = "NotFoundError";
  }
}

/**
 * An API response returned a 400 status code and its JSON body didn't include any `errors`
 */
export class BadRequestError extends ApiRequestError {
  constructor(responseData?: unknown, message?: string) {
    super(responseData, message);
    this.name = "BadRequestError";
  }
}

/**
 * An API response returned a 403 status code, indicating an issue with the authorization of the request.
 * Examples of a ForbiddenError could be the user's browser prevented the session cookie from
 * being created, or the user hasn't consented to the data sharing agreement.
 */
export class ForbiddenError extends ApiRequestError {
  constructor(responseData?: unknown, message?: string) {
    super(responseData, message);
    this.name = "ForbiddenError";
  }
}

/**
 * An API response returned a 500 status code
 */
export class InternalServerError extends ApiRequestError {
  constructor(responseData?: unknown, message?: string) {
    super(responseData, message);
    this.name = "InternalServerError";
  }
}

/**
 * An API response returned a 408 status code
 */
export class RequestTimeoutError extends ApiRequestError {
  constructor(responseData?: unknown, message?: string) {
    super(responseData, message);
    this.name = "RequestTimeoutError";
  }
}

/**
 * A GET request to an Application's `/documents` or User's `/documents` endpoint failed
 * @example new DocumentsLoadError({ application_id: 'mock_application_id'})
 */
export class DocumentsLoadError extends BasePortalError {
  // ID of the Claim the documents are associated with
  application_id?: string;
  // ID of the User the documents are associated with
  user_id?: string;

  constructor(
    metadata: {
      application_id?: string;
      user_id?: string;
    },
    name?: string,
    message?: string
  ) {
    super(message);
    this.application_id = metadata?.application_id;
    this.user_id = metadata?.user_id;
    this.name = name ?? "DocumentsLoadError";
  }
}

/**
 * A GET request to an Application's `/download` or User's `/download` endpoint failed
 * @example new DocumentDownloadError({application_id: 'mock_application_id'})
 */
export class DocumentDownloadError extends BasePortalError {
  // ID of the Claim the documents are associated with
  application_id?: string;
  // ID of the User the documents are associated with
  user_id?: string;

  constructor(
    metadata: {
      application_id?: string;
      user_id?: string;
    },
    name?: string,
    message?: string
  ) {
    super(message);
    this.application_id = metadata?.application_id;
    this.user_id = metadata?.user_id;
    this.name = name ?? "DocumentDownloadError";
  }
}

/**
 * A POST request to an Application's `/documents` endpoint failed
 * @example new DocumentsUploadError('mock_file_id', {application_id: 'mock_application_id'}, [{ field: "", type: "fineos", message: "File size limit" }])
 */
export class DocumentsUploadError extends BasePortalError {
  // ID of the Claim the documents are associated with
  application_id?: string;
  // ID of the change request the documents are associated with
  change_request_id?: string;
  // ID of the appeal the documents are associated with
  appeal_id?: string;
  // ID of the file causing errors, so the issues can be displayed inline
  file_id: string;
  // the validation issue returned by the API
  issues: Issue[];

  constructor(
    file_id: string,
    metadata: {
      application_id?: string;
      change_request_id?: string;
      appeal_id?: string;
    },
    issue: Issue | null = null,
    message?: string
  ) {
    super(message);
    this.application_id = metadata?.application_id;
    this.change_request_id = metadata?.change_request_id;
    this.appeal_id = metadata?.appeal_id;
    this.file_id = file_id;
    this.issues = issue ? [issue] : [];
    this.name = "DocumentsUploadError";
  }
}

/**
 * A GET request to the /claims/:id endpoint failed because the claim was withdrawn in FINEOS
 */
export class ClaimWithdrawnError extends BasePortalError {
  // ID of the Claim that was withdrawn
  fineos_absence_id: string;
  issues: Issue[];

  constructor(fineos_absence_id: string, issue: Issue, message?: string) {
    super(message);
    this.fineos_absence_id = fineos_absence_id;
    this.issues = [issue];
    this.name = "ClaimWithdrawnError";
  }
}

/**
 *  An API response returned a 503 status code
 */
export class ServiceUnavailableError extends ApiRequestError {
  constructor(responseData?: unknown, message?: string) {
    super(responseData, message);
    this.name = "ServiceUnavailableError";
  }
}

/**
 * An API response returned a 401 status code
 */
export class UnauthorizedError extends ApiRequestError {
  constructor(responseData?: unknown, message?: string) {
    super(responseData, message);
    this.name = "UnauthorizedError";
  }
}

/**
 * A request wasn't completed due to one or more validation issues
 * @example new ValidationError([{ field: "tax_identifier", type: "pattern", message: "Field didn't match \d{9}", namespace: "applications" }])
 */
export class ValidationError extends BasePortalError {
  // List of validation issues returned by the API
  issues: Issue[];

  constructor(issues: Issue[]) {
    super();
    this.issues = issues;
    this.name = "ValidationError";
  }
}
