import React, { useRef } from "react";

import PaginationNavigation from "../components/PaginationNavigation";
import PaginationSummary from "../components/PaginationSummary";
import { getMapOfPagesWithData } from "../utils/getMapOfPagesWithData";

interface PaginatedListProps {
  sortedListItems: React.JSX.Element[];
  pageSize: number;
}

/**
 * Takes in sorted list items and page size then returns a paginated list
 * @param sortedListItems an array of the list data items sorted in desired order.
 * example item of array: <li>...</li>
 * @param pageSize the number of items to display per page.
 * @returns a paginated list of the given data.
 */
const PaginatedList = ({ sortedListItems, pageSize }: PaginatedListProps) => {
  const totalRecords = sortedListItems.length;
  const totalPages = Math.ceil(totalRecords / pageSize);
  const showPagination = totalPages > 1;

  const mapOfPagesWithData = getMapOfPagesWithData(sortedListItems, pageSize);

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const listRef = useRef<null | HTMLTableSectionElement>(null);

  const handlePaginationNavigationClick = (pageOffset: number) => {
    const topOfTableCoordinate = Number(
      listRef.current?.getBoundingClientRect().top
    );
    // only scroll into view if table is not in view
    if (topOfTableCoordinate < 0) listRef.current?.scrollIntoView();
    setCurrentPage(pageOffset);
  };

  return (
    <React.Fragment>
      <ul className="add-list-reset">{mapOfPagesWithData.get(currentPage)}</ul>
      {showPagination && (
        <PaginationNavigation
          pageOffset={currentPage}
          totalPages={totalPages}
          onClick={handlePaginationNavigationClick}
        />
      )}
      {showPagination && (
        <PaginationSummary
          pageOffset={currentPage}
          pageSize={pageSize}
          totalRecords={totalRecords}
        />
      )}
    </React.Fragment>
  );
};

export default PaginatedList;
