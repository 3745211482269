import { LeaveRequest } from "src/models/LeaveRequest";

// Returns all absence paid leave case end dates for a Leave Request
export function getAbsencePaidLeaveCaseEndDates(leaveRequest: LeaveRequest) {
  const endDates = leaveRequest.absence_paid_leave_cases.map(
    (absenceCase) => absenceCase.end_date
  );

  return endDates;
}
