import Alert from "src/components/core/Alert";
import React from "react";
import { useTranslation } from "src/locales/i18n";

const UpdateLeaveDatesBanner = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="update-leave-dates-banner">
      <Alert
        className="margin-bottom-3"
        heading={t(
          "components.updateLeaveDatesBanner.updateLeaveDatesAlertTitle"
        )}
        state="info"
      >
        {t("components.updateLeaveDatesBanner.updateLeaveDatesAlertBody")}
      </Alert>
    </div>
  );
};

export default UpdateLeaveDatesBanner;
