import ApiResourceCollection from "../models/ApiResourceCollection";
import BaseApi from "./BaseApi";
import ChangeRequest from "../models/ChangeRequest";
import routes from "../routes";

export default class ChangeRequestsApi extends BaseApi {
  get basePath(): string {
    return routes.api.changeRequest;
  }

  get namespace(): string {
    return "change_request";
  }

  getChangeRequests = async (absence_case_id: string) => {
    const { data } = await this.request<{
      absence_case_id: string;
      change_requests: ChangeRequest[];
    }>("GET", undefined, {
      fineos_absence_id: absence_case_id,
    });

    return {
      changeRequests: new ApiResourceCollection<ChangeRequest>(
        "change_request_id",
        data.change_requests
      ),
    };
  };

  createChangeRequest = async (
    absence_case_id: string,
    optionalData: Partial<ChangeRequest> | undefined
  ) => {
    const { data } = await this.request<ChangeRequest>(
      "POST",
      `?fineos_absence_id=${absence_case_id}`,
      optionalData ?? {}
    );

    return { changeRequest: new ChangeRequest(data) };
  };

  deleteChangeRequest = async (change_request_id: string) => {
    await this.request<ChangeRequest>("DELETE", change_request_id);

    return {};
  };

  updateChangeRequest = async (
    change_request_id: string,
    patchData: Partial<ChangeRequest>
  ) => {
    const { data, warnings } = await this.request<ChangeRequest>(
      "PATCH",
      change_request_id,
      patchData
    );

    return {
      changeRequest: new ChangeRequest(data),
      warnings,
    };
  };

  submitChangeRequest = async (change_request_id: string) => {
    const { data, warnings } = await this.request<ChangeRequest>(
      "POST",
      `${change_request_id}/submit`
    );

    return {
      changeRequest: new ChangeRequest(data),
      warnings,
    };
  };
}
