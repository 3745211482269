import { formatDatePeriod, formatDateRange } from "src/utils/formatDateRange";

import Alert from "./core/Alert";
import ApplicationSplit from "src/models/ApplicationSplit";
import Heading from "./core/Heading";
import LeaveDatesInEachBenefitYear from "src/features/benefits-applications/benefit-years/LeaveDatesInEachBenefitYear";
import React from "react";
import calculateWaitingPeriod from "src/utils/calculateWaitingPeriod";
import { useTranslation } from "react-i18next";

interface LeaveDatesAlertProps {
  applicationSplit?: ApplicationSplit | null;
  endDate: string | null;
  headingLevel?: "2" | "3";
  startDate: string | null;
  showWaitingDayPeriod?: boolean;
  useNeutralBackgroundColor?: boolean;
  useFullDates?: boolean;
}

/**
 * Alert component shared across multiple pages for displaying
 * a claimant's leave dates
 */
function LeaveDatesAlert({
  applicationSplit,
  endDate,
  headingLevel = "2",
  startDate,
  showWaitingDayPeriod,
  useNeutralBackgroundColor = true,
  useFullDates = false,
}: LeaveDatesAlertProps) {
  // Don't render if leave dates aren't present yet
  if (!startDate || !endDate) return null;

  return (
    <React.Fragment>
      <Alert
        className="margin-top-2 margin-bottom-4"
        neutral={useNeutralBackgroundColor}
        noIcon
        state="info"
      >
        {applicationSplit ? (
          <React.Fragment>
            <LeaveDatesInEachBenefitYear
              applicationSplit={applicationSplit}
              showWaitingDayPeriod={showWaitingDayPeriod}
            />
          </React.Fragment>
        ) : (
          <LeaveDatesSingleBenefitYear
            startDate={startDate}
            endDate={endDate}
            headingLevel={headingLevel}
            showWaitingDayPeriod={showWaitingDayPeriod}
            useFullDates={useFullDates}
          />
        )}
      </Alert>
    </React.Fragment>
  );
}

interface LeaveDatesSingleBenefitYearProps {
  endDate: string;
  headingLevel: "2" | "3";
  startDate: string;
  showWaitingDayPeriod?: boolean;
  useFullDates?: boolean;
}

function LeaveDatesSingleBenefitYear(props: LeaveDatesSingleBenefitYearProps) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <React.Fragment>
        <Heading level={props.headingLevel} size="2">
          {t("components.leaveDatesAlert.heading")}
        </Heading>
        <p className="margin-top-1">
          {formatDateRange(props.startDate, props.endDate, props.useFullDates)}
        </p>
      </React.Fragment>

      {props.showWaitingDayPeriod && (
        <React.Fragment>
          <Heading level={props.headingLevel} size="2">
            {t("components.leaveDatesAlert.leaveDatesHeading")}
          </Heading>
          <p className="margin-top-1">
            {formatDatePeriod(
              calculateWaitingPeriod(props.startDate),
              props.useFullDates
            )}
          </p>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default LeaveDatesAlert;
