import User, {
  UserLeaveAdministrator,
  UserProfileCheckForUpdatesRequest,
  UserProfileCheckForUpdatesResponse,
  UserProfileHasUsableDataForApplicationResponse,
  UserProfileUpdate,
  UserRole,
} from "../models/User";

import BaseApi from "./BaseApi";
import routes from "../routes";

export default class UsersApi extends BaseApi {
  get basePath() {
    return routes.api.users;
  }

  get namespace() {
    return "users";
  }

  get headers() {
    return [
      { featureFlag: "enableUniversalProfilePilot" },
      { featureFlag: "enableMenaRaceOption" },
    ];
  }

  /**
   * Get the currently authenticated user
   */
  getCurrentUser = async () => {
    const { data } = await this.request<User>("GET", "current");
    sessionStorage.setItem("userID", data.user_id);
    const roles = this.createUserRoles(data.roles);
    const user_leave_administrators = this.createUserLeaveAdministrators(
      data.user_leave_administrators
    );

    return Promise.resolve({
      user: new User({ ...data, roles, user_leave_administrators }),
    });
  };

  updateUser = async (
    user_id: string,
    patchData: { [key: string]: unknown }
  ) => {
    const { data } = await this.request<User>("PATCH", user_id, patchData, {
      additionalHeaders: {},
    });
    const roles = this.createUserRoles(data.roles);
    const user_leave_administrators = this.createUserLeaveAdministrators(
      data.user_leave_administrators
    );

    return {
      user: new User({
        ...patchData,
        ...data,
        roles,
        user_leave_administrators,
      }),
    };
  };

  /**
   * Convert a user to an employer
   */
  convertUserToEmployer = async (
    user_id: string,
    postData: { employer_fein: string }
  ) => {
    const { data } = await this.request<User>(
      "POST",
      `${user_id}/convert-employer`,
      postData
    );
    const roles = this.createUserRoles(data.roles);
    const user_leave_administrators = this.createUserLeaveAdministrators(
      data.user_leave_administrators
    );

    return {
      user: new User({
        ...data,
        roles,
        user_leave_administrators,
      }),
    };
  };

  createUserLeaveAdministrators = (
    leaveAdmins: UserLeaveAdministrator[] = []
  ) => {
    return leaveAdmins.map(
      (leaveAdmin) => new UserLeaveAdministrator(leaveAdmin)
    );
  };

  createUserRoles = (roles: UserRole[] = []) => {
    return roles.map((role) => new UserRole(role));
  };

  userProfileHasUsableDataForApplication = async (user_id: string) => {
    const { data } = await this.request<object>(
      "GET",
      `${user_id}/profile/has-usable-data-for-application`
    );

    return new UserProfileHasUsableDataForApplicationResponse(data);
  };

  userProfileCheckForUpdates = async (
    user_id: string,
    postData: Readonly<UserProfileCheckForUpdatesRequest>
  ) => {
    const { data } = await this.request<object>(
      "POST",
      `${user_id}/profile/check-for-updates`,
      postData
    );

    return new UserProfileCheckForUpdatesResponse(data);
  };

  updateUserProfile = async (
    user_id: string,
    patchData: Readonly<UserProfileUpdate>
  ) => {
    await this.request<object>("PATCH", `${user_id}/profile`, patchData);

    return {};
  };
}
