import { AbsencePeriod } from "./AbsencePeriod";
import Address from "./Address";
import EmployerBenefit from "./EmployerBenefit";
import PreviousLeave from "./PreviousLeave";
import { WithClaimProperties } from "./WithClaimProperties";
import { fullName } from "src/utils/fullName";
import { merge } from "lodash";

/**
 * This model represents a Claim record that is formed from data directly pulled
 * from the Claims Processing System (CPS). This should not be confused with a record
 * from the API's Claims table, which is represented by the Claim model.
 * TODO (PORTAL-477): Rename this model to clarify this nuance.
 */
// TODO (PFMLPB-11144) Set the empty array to equal the data coming from the backend object which contains historical previous PFML leaves.
interface HistoricalPreviousLeaves {
  start_date: string;
  end_date: string;
}

/**
 * Possible values for Employer's changes "employer_changes[].type" field.
 * @enum {string}
 */
export const EmployerChangesType = {
  added: "Added",
  amended: "Amended",
  unchanged: "Unchanged",
  removed: "Removed",
} as const;

class EmployerClaimReview extends WithClaimProperties {
  employer_id: string;
  fineos_absence_id: string;
  created_at: string;
  first_name: string | null = null;
  middle_name: string | null = null;
  last_name: string | null = null;
  employer_benefits: EmployerBenefit[];
  date_of_birth: string | null = null;
  employer_fein: string;
  employer_dba: string | null;
  hours_worked_per_week: number | null = null;
  residential_address: Address;
  tax_identifier: string | null = null;
  previous_leaves: PreviousLeave[];
  payment_schedule_type = "Leave Start-Based";

  computed_start_dates: {
    other_reason: string | null;
    same_reason: string | null;
  };

  previous_pfml_leave_periods: HistoricalPreviousLeaves[];

  constructor(attrs: Partial<EmployerClaimReview>) {
    // Recursively merge with the defaults
    super();
    merge(this, attrs);

    this.absence_periods = this.absence_periods.map(
      (absence_period) => new AbsencePeriod(absence_period)
    );

    if (this.employer_benefits !== undefined) {
      this.employer_benefits = this.employer_benefits.map((benefit, i) => {
        benefit.employer_benefit_id = `${i}`;
        benefit.employer_changes = EmployerChangesType.unchanged;
        return new EmployerBenefit(benefit);
      });
    }

    if (this.previous_leaves !== undefined) {
      this.previous_leaves = this.previous_leaves.map((leave, i) => {
        leave.previous_leave_id = `${i}`;
        leave.employer_changes = EmployerChangesType.unchanged;
        return new PreviousLeave(leave);
      });
    }
  }

  get fullName() {
    return fullName(this.first_name, this.middle_name, this.last_name);
  }
}

export default EmployerClaimReview;
