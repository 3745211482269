export class WaitingPeriod {
  waiting_period_end_date: string;
  waiting_period_start_date: string;

  constructor(attrs: Partial<WaitingPeriod>) {
    Object.assign(this, attrs);
  }
}

export default WaitingPeriod;
