import { ClaimEmployee, ClaimEmployer } from "./Claim";

import { AbsencePeriod } from "./AbsencePeriod";
import { IntermittentLeaveResponse } from "./IntermittentLeaveEpisode";
import { WithClaimProperties } from "./WithClaimProperties";
import { orderBy } from "lodash";

class ClaimDetail extends WithClaimProperties {
  application_id: string;
  created_at: string;
  does_claim_span_benefit_years: boolean;
  employee: ClaimEmployee | null;
  employer: ClaimEmployer | null;
  fineos_absence_id: string;
  fineos_notification_id: string;
  has_paid_payments: boolean;
  intermittent_leave_episodes: IntermittentLeaveResponse[];
  payment_schedule_type: string;
  document_requirements: DocumentRequirements[] = [];
  has_extensions: boolean;

  constructor(attrs?: Partial<ClaimDetail>) {
    super();
    if (!attrs) {
      return;
    }

    if (!attrs.intermittent_leave_episodes)
      attrs.intermittent_leave_episodes = [];

    Object.assign(this, attrs);

    if (attrs.employee) {
      this.employee = new ClaimEmployee(attrs.employee);
    }

    this.absence_periods = orderBy(
      this.absence_periods.map(
        (absence_period) => new AbsencePeriod(absence_period)
      ),
      "startDate"
    );
  }
}

export interface AbsencePeriodDates {
  startDate: string;
  endDate: string;
}

export interface DocumentRequirements {
  document_type: string;
  upload_date: string | null;
}

export default ClaimDetail;
