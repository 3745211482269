/**
 * Payment response associated with the Claim from API
 */

import dayjs from "dayjs";
import dayjsBusinessTime from "dayjs-business-time";

dayjs.extend(dayjsBusinessTime);

export type PaymentStatus =
  | "Cancelled"
  | "Delayed"
  | "Pending"
  | "PendingWithExpectedDelay"
  | "Sent to bank";

export type WritebackTransactionStatus =
  | "Payment Validation Error"
  | "EFT Account Information Error"
  | "EFT Pending Bank Validation"
  | "Payment System Error"
  | "Address Validation Error"
  | "Pending Payment Audit"
  | "Bank Processing Error"
  | "Processed"
  | "Paid"
  | "Posted"
  | "Leave Plan In Review"
  | "Payment Audit Error"
  | "DUA Additional Income"
  | "DIA Additional Income"
  | "SelfReported Additional Income"
  | "Exempt Employer"
  | "Max Weekly Benefits Exceeded"
  | "InvalidPayment WaitingWeek"
  | "InvalidPayment PaidDate"
  | "InvalidPayment LeaveDateChange"
  | "InvalidPayment PayAdjustment"
  | "InvalidPayment NameMismatch"
  | "Payment Audit In Progress"
  | "PUB Check Voided"
  | "PUB Check Undeliverable"
  | "PUB Check Stale";

export type LineTypeCategory =
  | "Adjustments"
  | "Child support"
  | "Employer reimbursements"
  | "Gross payment amount"
  | "Net payment amount"
  | "Other leave, income and benefits"
  | "Overpayment"
  | "Tax withholding";

export const PAYMENT_METHOD = {
  CHECK: "Check",
  ELEC_FUNDS_TRANSFER: "Elec Funds Transfer",
  PREPAID_CARD: "Prepaid Card",
};

/* 
  Payments can be delayed for any of the reasons listed in the WritebackTransactionStatus type.
  The amount of time it takes the contact center to process a delayed payment varies by the reason mapped in PROCESSING_DAYS_PER_DELAY.
  (PROCESSING_DAYS_PER_DELAY is managed on the backend and exposed in the API via the num_days_to_process_delay field).
  We will only show information about the delay after the contact center has had enough time to resolve the payment
  isAfterDelayProcessingTime calculates if the current date is after the time it should have taken to 
  process the delay which would be the transaction date + the number of days it takes to
  process that delay reason (3 days by default).
*/

export const isAfterDelayProcessingTime = (payment_item: Payment): boolean => {
  const { transaction_date, transaction_date_could_change } = payment_item;
  const todaysDate = dayjs();

  const transactionDate = dayjs(transaction_date);
  const daysToProcess = payment_item.num_days_to_process_delay ?? 3;
  const showImmediately = daysToProcess === 0 || transaction_date_could_change;
  return (
    showImmediately ||
    todaysDate.isAfter(transactionDate.addBusinessDays(daysToProcess), "day")
  );
};

/**
 * List of payments associated with the Claim
 */
export class Payment {
  payment_id: string;
  period_start_date: string;
  period_end_date: string;
  amount: number | null; // net amount of payment
  sent_to_bank_date: string | null;
  payment_method: string;
  payment_details: PaymentDetails[]; // more than one payment detail obj, lump sum
  expected_send_date_start: string | null;
  expected_send_date_end: string | null;
  cancellation_date: string | null;
  status: PaymentStatus; // deprecated, use `frontend_status` until PFMLPB-7971 is complete
  writeback_transaction_status: WritebackTransactionStatus;
  transaction_date: string | null;
  transaction_date_could_change: boolean;
  num_days_to_process_delay: number | null;

  constructor(attrs?: Partial<Payment>) {
    Object.assign(this, attrs);
  }

  /**
   * Gets the status for a payment.
   * Checks for payments that are delayed, but within the expected delay window,
   * and returns the "PendingWithExpectedDelay" status.
   * This check will be obsolete when PFMLPB-7971 is complete and the API is returning this
   * status. Until then, the frontend should defensively check for and apply this status.
   */
  get frontend_status() {
    if (this.isDelayedWithExpectedDelay) {
      return "PendingWithExpectedDelay";
    }

    return this.status;
  }

  /**
   * Gets gross amount of payment item
   */
  get grossAmount() {
    return this.payment_details
      .flatMap((payment_detail) => payment_detail.payment_lines)
      .find(
        ({ line_type_category }) =>
          line_type_category === "Gross payment amount"
      )?.amount;
  }

  /**
   * Checks if payment has lump sum
   */
  get isLumpSumPayment() {
    return this.payment_details.length > 1;
  }

  /**
   * Determines if the payment is a zero dollar payment
   */
  get isZeroDollar() {
    // Cancelled payments may have $0 but should be handled like cancelled payments
    return this.amount === 0 && this.frontend_status !== "Cancelled";
  }

  /**
   * This method is used to determine if a delayed payment should be treated as
   * "Delayed" or "Pending". The API sets a status of "Delayed" even when the delay
   * is expected to be resolved on its own in a known number of days.
   * Note that once the API sends the "PendingWithExpectedDelay" status, we can
   * remove this method. See: PFMLPB-7971
   */
  get isDelayedWithExpectedDelay() {
    if (this.status !== "Delayed") {
      // payment is not delayed
      return false;
    }

    if (this.transaction_date_could_change) {
      // delayed but date could change - treat this like a Pending payment
      return true;
    }

    if (!this.transaction_date) {
      // no transaction date - can't check date against expected delay times
      // treat like a normal Delay
      return false;
    }

    const isBeforeDelayProcessingTime = !isAfterDelayProcessingTime(this);
    if (isBeforeDelayProcessingTime) {
      // this payment is delayed, but still within the acceptable number of days for delays of its type
      // treat like a Pending payment
      return true;
    }

    return false;
  }
}

/**
 * Payment details associated with a payment object
 */
export class PaymentDetails {
  amount: number;
  payment_details_id: string;
  payment_lines: PaymentLines[];
  period_start_date: string;
  period_end_date: string;

  constructor(attrs?: Partial<PaymentDetails>) {
    Object.assign(this, attrs);
  }

  // any payment_category that's not a Gross payment amount or a Net payment amount is considered an adjustment
  get hasAdjustments() {
    return !!this.payment_lines.find(
      (paymentLine) =>
        !["Gross payment amount", "Net payment amount"].includes(
          paymentLine.line_type_category
        )
    );
  }
}

/**
 * Payment line items associated with payment detail item
 */
export interface PaymentLines {
  payment_line_id: string;
  amount: number;
  line_type: string;
  line_type_category: string;
}

export default Payment;
