import withAppeal, { WithAppealProps } from "../../../hoc/withAppeal";

import Appeal from "../../../models/Appeal";
import InputDate from "../../../components/core/InputDate";
import QuestionPage from "../../../components/QuestionPage";
import React from "react";
import useFormState from "../../../hooks/useFormState";
import useFunctionalInputProps from "../../../hooks/useFunctionalInputProps";
import { useTranslation } from "react-i18next";

export const fields: Array<keyof Appeal> = ["originally_decided_at"];

export const NoticeDate = (props: WithAppealProps) => {
  const { appLogic, appeal } = props;
  const { t } = useTranslation();

  const { formState, updateFields } = useFormState(appeal);
  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const handleSave = async () => {
    await appLogic.appeals.update(appeal.appeal_id, formState);
  };

  return (
    <QuestionPage
      onSave={handleSave}
      title={t("pages.appealsNoticeDate.title")}
    >
      <InputDate
        label={t("pages.appealsNoticeDate.label")}
        example={t("components.form.dateInputExample")}
        dayLabel={t("components.form.dateInputDayLabel")}
        monthLabel={t("components.form.dateInputMonthLabel")}
        yearLabel={t("components.form.dateInputYearLabel")}
        {...getFunctionalInputProps("originally_decided_at")}
      />
    </QuestionPage>
  );
};

export default withAppeal(NoticeDate);
