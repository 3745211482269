import React, { useEffect, useState } from "react";

import ExternalLink from "./core/ExternalLink";
import { Trans } from "react-i18next";
import classnames from "classnames";

interface UnsupportedBrowserBannerProps {
  /**
   * Force the banner to render regardless of whether the
   * browser is supported or not. Useful for testing or
   * previewing the component in a supported browser.
   */
  forceRender?: boolean;
}

const initialBrowserInfoState = {
  browser: "",
  version: -1,
  vendor: "",
};

/**
 * Banner displayed at the top of the site to indicate the site
 * does not support the current web browser, when the user is
 * using Internet Explorer versions 11 and below.
 */
const UnsupportedBrowserBanner = (props: UnsupportedBrowserBannerProps) => {
  const { forceRender } = props;
  const [browserInfo, setBrowserInfo] = useState(initialBrowserInfoState);

  useEffect(() => {
    if (window.navigator !== undefined) {
      setBrowserInfo(getBrowserInfo());
    }
  }, []);

  const { browser, version, vendor } = browserInfo;
  const isIE = browser === "Internet Explorer" && version <= 11;

  const classes = classnames(
    "c-unsupported-browser-banner bg-yellow padding-1 text-center",
    {
      "display-block": forceRender === true || isIE,
    }
  );

  /**
   * The CSS @media query approach doesn't work for IE 9 and below, and conditional HTML
   * comments don't work for IE10 and above, so we use a combination. IE9 and below doesn't
   * support Content-Security-Policy, so we don't need to worry about that with this inline <style>:
   */
  const conditionalHTMLComment = `<!--[if lte IE 9]><style>.c-unsupported-browser-banner { display: block }</style><![endif]-->`;

  const conditionalMessage = version === -1 ? "oldMessage" : "message";

  return (
    <React.Fragment>
      <span dangerouslySetInnerHTML={{ __html: conditionalHTMLComment }} />
      <div role="alert" className={classes}>
        <Trans
          i18nKey={`components.unsupportedBrowserBanner.${conditionalMessage}`}
          values={{ browser, version, vendor }}
          components={{
            "update-link": (
              <ExternalLink href="https://browser-update.org/update.html" />
            ),
          }}
        />
      </div>
    </React.Fragment>
  );
};

function getBrowserInfo() {
  const { vendor, userAgent } = navigator;
  const isInternetExplorer = /Trident\/\d+\.\d+/gi;
  if (isInternetExplorer.test(userAgent)) {
    const internetExplorerVersion = /(MSIE\s|rv:)(\d+)\.\d+/gi;
    // Example results: ["rv:11.0", "rv:", "11"], ["MSIE 8.0", "MSIE ", "8"]
    const [, , version] = internetExplorerVersion.exec(userAgent) || [];
    return {
      browser: "Internet Explorer",
      version: parseInt(version),
      vendor,
    };
  }
  return initialBrowserInfoState;
}

export default UnsupportedBrowserBanner;
