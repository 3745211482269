import {
  createRequestUrl,
  fetchErrorToNetworkError,
  getAuthorizationHeader,
  handleNotOkResponse,
} from "src/api/BaseApi";

/**
 * Makes a request to a given download API endpoint and downloads and returns document content
 */
async function downloadDocument(
  basePath: string,
  subPath: string,
  namespace: string,
  content_type: string
) {
  const method = "GET";
  const url = createRequestUrl(method, basePath, subPath);
  const authHeader = await getAuthorizationHeader();

  const headers = {
    ...authHeader,
    "Content-Type": content_type,
  };

  let blob: Blob, response: Response;
  try {
    response = await fetch(url, { headers, method });
    blob = await response.blob();
  } catch (error) {
    throw fetchErrorToNetworkError(error);
  }

  if (!response.ok) {
    handleNotOkResponse(response, [], namespace);
  }

  return blob;
}

export default downloadDocument;
