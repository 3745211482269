import { DocumentDownloadError, DocumentsLoadError } from "../errors";
import { DocumentType, UserDocument } from "../models/Document";
import { useMemo, useState } from "react";

import ApiResourceCollection from "../models/ApiResourceCollection";
import DocumentsApi from "../api/DocumentsApi";
import { ErrorsLogic } from "./useErrorsLogic";
import tracker from "src/services/tracker";
import useCollectionState from "./useCollectionState";

const useTaxDocumentsLogic = ({
  errorsLogic,
}: {
  errorsLogic: ErrorsLogic;
}) => {
  /**
   * State representing the collection of documents for the current user.
   * Initialize to empty collection, but will eventually store the document
   * state as API calls are made to fetch the documents on a per-user basis,
   * and as new documents are created.
   *
   * The collection from useCollectionState stores all documents together,
   * and we filter documents based on user_id to provide the correct documents
   * to the requesting components.
   */

  const { collection: taxDocuments, addItems: addDocuments } =
    useCollectionState(
      new ApiResourceCollection<UserDocument>("fineos_document_id")
    );

  const documentsApi = useMemo(() => new DocumentsApi(), []);
  const [loadedTaxDocuments, setLoadedTaxDocuments] = useState<{
    [user_id: string]: { isLoading: boolean };
  }>({});

  /**
   * Check if docs for this user have been loaded
   * We use a separate array and state here, rather than using the collection,
   * because documents that don't have items won't be represented in the collection.
   */
  const hasLoadedTaxDocuments = (user_id: string) =>
    user_id in loadedTaxDocuments &&
    loadedTaxDocuments[user_id].isLoading === false;

  const isLoadingTaxDocuments = (user_id: string) =>
    user_id in loadedTaxDocuments &&
    loadedTaxDocuments[user_id].isLoading === true;

  /**
   * Load all documents for a user's claim
   * This must be called before documents are available
   */
  const loadAll = async (user_id: string) => {
    // if documents already contains docs for user_id, don't load again
    // or if we started making a request to the API to load documents, don't load again
    if (hasLoadedTaxDocuments(user_id) || isLoadingTaxDocuments(user_id))
      return;

    errorsLogic.clearErrors();

    setLoadedTaxDocuments((loadingTaxDocuments) => {
      const docs = { ...loadingTaxDocuments };
      docs[user_id] = {
        isLoading: true,
      };
      return docs;
    });

    try {
      const { documents: loadedDocuments } =
        await documentsApi.getUserDocuments(user_id, DocumentType.taxForm1099);
      tracker.trackEvent("Retrieved tax documents for user", {
        userId: user_id,
        documentCount: await loadedDocuments.items.length,
      });
      addDocuments(await loadedDocuments.items);
      setLoadedTaxDocuments((loadingTaxDocuments) => {
        const docs = { ...loadingTaxDocuments };
        docs[user_id] = {
          isLoading: false,
        };
        return docs;
      });
    } catch (error) {
      const errorMetadata = { user_id };
      const name = "TaxDocumentsLoadError";
      tracker.trackEvent(name, {
        userId: user_id,
      });
      errorsLogic.catchError(new DocumentsLoadError(errorMetadata, name));
    }
  };

  /**
   * Download document from the API and sets app errors if any
   */
  const download = async (user_id: string, document: UserDocument) => {
    errorsLogic.clearErrors();
    try {
      const response = await documentsApi.downloadUserDocument(
        user_id,
        document
      );
      tracker.trackEvent("Downloaded tax document for user", {
        userId: user_id,
        documentName: document.name ?? "",
        fineosDocumentId: document.fineos_document_id,
      });
      return response;
    } catch (error) {
      const errorMetadata = { user_id };
      const name = "TaxDocumentDownloadError";
      tracker.trackEvent(name, {
        userId: user_id,
        documentName: document.name ?? "",
        fineosDocumentId: document.fineos_document_id,
      });
      errorsLogic.catchError(new DocumentDownloadError(errorMetadata, name));
    }
  };

  return {
    download,
    hasLoadedTaxDocuments,
    isLoadingTaxDocuments,
    taxDocuments,
    loadAll,
  };
};

export default useTaxDocumentsLogic;
export type TaxDocumentsLogic = ReturnType<typeof useTaxDocumentsLogic>;
