import useFormState, { FormState } from "../../../hooks/useFormState";
import withBenefitYears, {
  WithBenefitYearsProps,
} from "src/hoc/withBenefitYears";
import withChangeRequest, {
  WithChangeRequestProps,
} from "src/hoc/withChangeRequest";
import withClaimDetail, { WithClaimDetailProps } from "src/hoc/withClaimDetail";

import { AppLogic } from "../../../hooks/useAppLogic";
import { ChangeRequestType } from "../../../models/ChangeRequest";
import ClaimDetail from "src/models/ClaimDetail";
import ConditionalContent from "../../../components/ConditionalContent";
import ExternalLink from "src/components/core/ExternalLink";
import Fieldset from "../../../components/core/Fieldset";
import Hint from "src/components/core/Hint";
import InputChoiceGroup from "../../../components/core/InputChoiceGroup";
import InputDate from "../../../components/core/InputDate";
import QuestionPage from "../../../components/QuestionPage";
import React from "react";
import ReviewHeading from "src/components/ReviewHeading";
import ReviewRow from "../../../components/ReviewRow";
import { Trans } from "react-i18next";
import formatDate from "../../../utils/formatDate";
import { pick } from "lodash";
import routeWithParams from "../../../utils/routeWithParams";
import routes from "../../../routes";
import useFunctionalInputProps from "../../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../../locales/i18n";

export const fields = [
  "change_request.change_request_type",
  "change_request.end_date",
];

type TypeProps = WithClaimDetailProps &
  WithChangeRequestProps &
  WithBenefitYearsProps & {
    query: {
      change_request_id: string;
      absence_id: string;
    };
    appLogic: AppLogic;
  };

export const Type = (props: TypeProps) => {
  const { t } = useTranslation();
  const { formState, getField, updateFields, clearField } = useFormState(
    pick(props, fields).change_request
  );

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: props.appLogic.errors,
    formState,
    updateFields,
  });

  const isExtension =
    formState.change_request_type === ChangeRequestType.modification ||
    formState.change_request_type === ChangeRequestType.extension;
  const isCancellation =
    formState.change_request_type === ChangeRequestType.cancellation;

  const { currentBenefitYear, nextBenefitYear, latestBenefitYear } = props;
  const handleSubmit = async () => {
    await props.appLogic.changeRequests.update(
      props.query.change_request_id,
      formState,
      props.claim_detail,
      { currentBenefitYear, nextBenefitYear, latestBenefitYear },
      props.user
    );
  };

  const choices = [
    {
      checked: isExtension,
      label: t("pages.claimsModifyType.modificationChoiceLabel"),
      value: ChangeRequestType.extension,
      hint: t("pages.claimsModifyType.modificationChoiceHint"),
    },
    {
      checked: isCancellation,
      label: t("pages.claimsModifyType.cancelChoiceText"),
      value: ChangeRequestType.cancellation,
      hint: t("pages.claimsModifyType.cancelChoiceHint"),
    },
  ];

  return (
    <QuestionPage
      title={t("pages.claimsModifyType.title")}
      onSave={handleSubmit}
      backButtonLink={routeWithParams("applications.status.claim", {
        absence_id: props.query.absence_id,
      })}
    >
      <InputChoiceGroup
        {...getFunctionalInputProps("change_request_type")}
        hint={t("pages.claimsModifyType.choiceHint")}
        choices={choices}
        type="radio"
        label={t("pages.claimsModifyType.choiceLabel")}
      />

      <ConditionalContent
        fieldNamesClearedWhenHidden={["end_date"]}
        getField={getField}
        updateFields={updateFields}
        clearField={clearField}
        visible={isExtension}
      >
        <Fieldset>
          <Hint>
            <Trans
              i18nKey="pages.claimsModifyType.modificationHint"
              components={{
                "contact-center-phone-link": (
                  <a href={`tel:${t("shared.contactCenterPhoneNumber")}`} />
                ),
                strong: <strong />,
                li: <li />,
                ul: <ul className="usa-list" />,
              }}
            />
          </Hint>
          <InputLeaveDate
            formState={formState}
            updateFields={updateFields}
            appLogic={props.appLogic}
            claimDetail={props.claim_detail}
          />
        </Fieldset>
      </ConditionalContent>
      <ConditionalContent
        fieldNamesClearedWhenHidden={["end_date"]}
        getField={getField}
        updateFields={updateFields}
        clearField={clearField}
        visible={isCancellation}
      >
        <Fieldset>
          <Hint>
            <Trans
              i18nKey="pages.claimsModifyType.cancellationHint"
              components={{
                "overpayments-link": (
                  <ExternalLink href={routes.external.massgov.overpayments} />
                ),
                strong: <strong />,
                li: <li />,
                ul: <ul className="usa-list" />,
              }}
            />
          </Hint>
          <InputLeaveDate
            formState={formState}
            updateFields={updateFields}
            appLogic={props.appLogic}
            claimDetail={props.claim_detail}
          />
        </Fieldset>
      </ConditionalContent>
    </QuestionPage>
  );
};

const InputLeaveDate = ({
  formState,
  updateFields,
  appLogic,
  claimDetail,
}: {
  formState: FormState["formState"];
  updateFields: FormState["updateFields"];
  appLogic: AppLogic;
  claimDetail: ClaimDetail;
}) => {
  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ReviewHeading level="2">
        {t("pages.claimsModifyType.modificationReviewDates")}
      </ReviewHeading>
      <ReviewRow
        noBorder
        level="3"
        label={t("pages.claimsModifyType.currentLeaveDatesLabel")}
      >
        <p title="current leave dates value">
          <Trans
            i18nKey="pages.claimsModifyType.currentLeaveDatesValue"
            values={{
              startDate: formatDate(claimDetail?.startDate).short(),
              endDate: formatDate(claimDetail?.endDate).short(),
            }}
          />
        </p>
      </ReviewRow>
      <InputDate
        {...getFunctionalInputProps("end_date")}
        label={t("pages.claimsModifyType.endDateLabel")}
        example={t("components.form.dateInputExample")}
        dayLabel={t("components.form.dateInputDayLabel")}
        monthLabel={t("components.form.dateInputMonthLabel")}
        yearLabel={t("components.form.dateInputYearLabel")}
        smallLabel
      />
    </React.Fragment>
  );
};

export default withBenefitYears(withChangeRequest(withClaimDetail(Type)));
