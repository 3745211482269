import { StartEndDates } from "types/time";
import dayjs from "dayjs";

const WAITING_PERIOD_DAYS = 7;

export type WaitingPeriod = StartEndDates;

export default function calculateWaitingPeriod(
  startDateOfLeave: string | dayjs.Dayjs
): WaitingPeriod {
  const startDate = dayjs(startDateOfLeave).startOf("day");
  return {
    startDate,
    endDate: calculateWaitingPeriodEnd(startDate),
  };
}

function calculateWaitingPeriodEnd(startDateOfLeave: dayjs.Dayjs): dayjs.Dayjs {
  // Start date of the leave is day 1 of the waiting period, so subtract one
  // from the number of waiting period days for getting the end date (i.e., if
  // the waiting period is 7 days and leave starts today, we want the date that
  // is 6 days from now because today counts as one of the 7)
  return startDateOfLeave.add(WAITING_PERIOD_DAYS - 1, "day");
}
