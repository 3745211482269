function ensureUrlSameOrigin(url: string, origin: string): string {
  const urlObj = new URL(url);
  const originObj = new URL(origin);
  if (urlObj.origin === originObj.origin) {
    return url;
  }
  const newUrl = new URL(url);
  newUrl.host = originObj.host;
  newUrl.protocol = originObj.protocol;
  return newUrl.toString();
}

export default ensureUrlSameOrigin;
