// class that stores and retrieves a named item from local storage
class LocalStorageItem {
  private readonly key: string;
  private readonly defaultValue: string | null;

  constructor(key: string, defaultValue: string | null = null) {
    this.key = key;
    this.defaultValue = defaultValue;
  }

  set(value: string): void {
    localStorage.setItem(this.key, value);
  }

  get(defaultValue: string | null = null): string | null {
    const value = localStorage.getItem(this.key);
    if (!!value) {
      return value;
    }
    if (defaultValue !== null) {
      return defaultValue;
    }
    return this.defaultValue;
  }

  remove(): void {
    localStorage.removeItem(this.key);
  }
}

export default LocalStorageItem;
