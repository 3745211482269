import React from "react";
import { useTranslation } from "src/locales/i18n";
import useUniqueId from "src/hooks/useUniqueId";

type LinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export interface ExternalLinkProps {
  readonly children?: React.ReactNode;
  readonly href: string;
  readonly showExternalIcon?: boolean;
  readonly newTab?: boolean;
  readonly srDescribe?: boolean;
  readonly otherProps?: LinkProps & { [key: string]: unknown };
}

export default function ExternalLink(props: ExternalLinkProps) {
  const { t } = useTranslation();
  const linkDescribeId = useUniqueId("link-describe-");
  const openInNewTab = props.newTab !== false;

  const linkProps: LinkProps = {
    href: props.href,
    rel: "noopener noreferrer",
  };

  if (openInNewTab) {
    linkProps.target = "_blank";
  }

  if (props.showExternalIcon) {
    linkProps.className = "usa-link--external";
  }

  let linkDescriptionElement = null;
  if (props.srDescribe) {
    const linkDescribeFragments = [t("components.externalLink.external")];
    if (openInNewTab) {
      linkDescribeFragments.push(t("components.externalLink.newTab"));
    }

    const linkDescribeText = linkDescribeFragments.join(" ");

    linkProps["aria-describedby"] = linkDescribeId;

    linkDescriptionElement = (
      <span id={linkDescribeId} hidden>
        {linkDescribeText}
      </span>
    );
  }

  return (
    <React.Fragment>
      <a {...linkProps} {...props.otherProps}>
        {props.children}
      </a>

      {linkDescriptionElement}
    </React.Fragment>
  );
}
