import { useId } from "react";

/**
 * Generate a unique ID. This is often useful for associating field
 * @example const id = useUniqueId("choice")
 */

function useUniqueId(prefix?: string) {
  return (prefix || "") + useId();
}

export default useUniqueId;
