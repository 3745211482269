import { ErrorsLogic } from "./useErrorsLogic";
import WaitingPeriod from "../models/WaitingPeriod";
import WaitingPeriodsApi from "src/api/WaitingPeriodsApi";
import { useState } from "react";

const useWaitingPeriodsLogic = ({
  errorsLogic,
}: {
  errorsLogic: ErrorsLogic;
}) => {
  const waitingPeriodsApi = new WaitingPeriodsApi();

  const [waitingPeriods, setWaitingPeriods] = useState<WaitingPeriod[]>();
  const [isLoadingWaitingPeriods, setIsLoadingWaitingPeriods] =
    useState<boolean>();
  const hasLoadedWaitingPeriods = !!waitingPeriods;

  // Load waiting periods from API
  const loadWaitingPeriods = async (absenceId: string) => {
    if (isLoadingWaitingPeriods || hasLoadedWaitingPeriods) return;

    setIsLoadingWaitingPeriods(true);
    errorsLogic.clearErrors();
    try {
      const waitingPeriods = await waitingPeriodsApi.getWaitingPeriods(
        absenceId
      );
      setWaitingPeriods(waitingPeriods);
    } catch (error) {
      errorsLogic.catchError(error);
      setWaitingPeriods([]);
    } finally {
      setIsLoadingWaitingPeriods(false);
    }
  };

  return {
    loadWaitingPeriods,
    waitingPeriods,
    hasLoadedWaitingPeriods,
    isLoadingWaitingPeriods,
  };
};

export default useWaitingPeriodsLogic;
export type WaitingPeriodsLogic = ReturnType<typeof useWaitingPeriodsLogic>;
