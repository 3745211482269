import IntermittentLeaveEpisode, {
  IntermittentLeaveResponse,
} from "src/models/IntermittentLeaveEpisode";

import { ErrorsLogic } from "./useErrorsLogic";
import IntermittentLeaveApi from "src/api/IntermittentLeaveApi";
import { useState } from "react";

const useReportLeaveEpisodeLogic = ({
  errorsLogic,
}: {
  errorsLogic: ErrorsLogic;
}) => {
  const [reportedLeaveEpisodes, setReportedLeaveEpisodes] =
    useState<IntermittentLeaveResponse[]>();
  const [loading, setLoading] = useState(false);
  const reportLeaveEpisodeApi = new IntermittentLeaveApi();

  const clearReportedLeaveEpisodes = () => {
    setReportedLeaveEpisodes([]);
  };
  const reportLeaveEpisode = async (
    absenceId: string,
    optionalData?: Partial<IntermittentLeaveEpisode>
  ) => {
    setLoading(true);
    errorsLogic.clearErrors();
    setReportedLeaveEpisodes([]);
    try {
      const { leave_episodes } =
        await reportLeaveEpisodeApi.postIntermittentLeaveEpisode(
          absenceId,
          optionalData
        );

      setReportedLeaveEpisodes(leave_episodes);
    } catch (error) {
      errorsLogic.catchError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    reportedLeaveEpisodes,
    reportLeaveEpisode,
    loading,
    clearReportedLeaveEpisodes,
  };
};

export default useReportLeaveEpisodeLogic;
