import { get, pick } from "lodash";
import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "../../hoc/withBenefitsApplication";

import Alert from "../../components/core/Alert";
import CaptionedImage from "../../components/core/CaptionedImage";
import ConditionalContent from "../../components/ConditionalContent";
import { EmploymentStatus as EmploymentStatusEnum } from "../../models/BenefitsApplication";
import Heading from "../../components/core/Heading";
import InputText from "../../components/core/InputText";
import QuestionPage from "../../components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import employerFeinW2Image from "../../components/EmployerFeinW2.png";
import useFormState from "../../hooks/useFormState";
import useFunctionalInputProps from "../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../locales/i18n";

export const fields = ["claim.employment_status", "claim.employer_fein"];

export const EmploymentStatus = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const initialFormState = pick(props, fields).claim || {
    employment_status: undefined,
  };

  // If the radio buttons are disabled, hard-code the field so that validations pass
  initialFormState.employment_status = EmploymentStatusEnum.employed;

  const { formState, getField, updateFields, clearField } =
    useFormState(initialFormState);
  const employment_status = get(formState, "employment_status");

  const handleSave = () =>
    appLogic.benefitsApplications.update(claim.application_id, formState);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  return (
    <QuestionPage
      title={t("pages.claimsEmploymentStatus.title")}
      onSave={handleSave}
    >
      <Alert state="info" neutral>
        <Trans
          i18nKey="pages.claimsEmploymentStatus.alertBody"
          components={{
            "contact-center-phone-link": (
              <a href={`tel:${t("shared.contactCenterPhoneNumber")}`} />
            ),
            p: <p />,
          }}
        />
      </Alert>

      <ConditionalContent
        fieldNamesClearedWhenHidden={["employer_fein"]}
        getField={getField}
        clearField={clearField}
        updateFields={updateFields}
        visible={employment_status === EmploymentStatusEnum.employed}
      >
        <Heading level="2" size="1">
          {t("pages.claimsEmploymentStatus.sectionLabel")}
        </Heading>

        <Heading level="3">
          {t("pages.claimsEmploymentStatus.feinHowToFindSubhead")}
        </Heading>
        <p>{t("pages.claimsEmploymentStatus.feinHowToFind_1")}</p>

        <CaptionedImage
          alt={t("pages.claimsEmploymentStatus.feinImageAlt")}
          caption={t("pages.claimsEmploymentStatus.feinImageCaption")}
          className="margin-0"
          src={employerFeinW2Image}
        />

        <p>{t("pages.claimsEmploymentStatus.feinHowToFind_2")}</p>

        <InputText
          {...getFunctionalInputProps("employer_fein")}
          inputMode="numeric"
          label={t("pages.claimsEmploymentStatus.feinLabel")}
          mask="fein"
          smallLabel
        />
      </ConditionalContent>
    </QuestionPage>
  );
};

export default withBenefitsApplication(EmploymentStatus);
