import ChangeRequest from "./ChangeRequest";
import dayjs from "dayjs";

class BenefitYear {
  benefit_year_end_date: string;
  benefit_year_start_date: string;
  employee_id?: string;
  current_benefit_year: boolean;
  invalid_benefit_years_since: string | null;

  constructor(attrs: Partial<BenefitYear>) {
    Object.assign(this, attrs);
  }
}

export const startsLessThanSixtyDaysInFuture = (
  submittedDate?: string,
  benefit_year_start_date?: string | null
): boolean => {
  if (!benefit_year_start_date) return false;
  const dateSixtyDaysBeforeBenefitYearStarts = dayjs(
    benefit_year_start_date
  ).subtract(60, "day");

  const dateToStartFrom = dayjs(submittedDate);

  return dateToStartFrom.isAfter(dateSixtyDaysBeforeBenefitYearStarts);
};

/* 
  Checks whether a claim that spans BYs is submitted more than 60 days before the next benefit year.
  We have to check if the current benefit year is when the claim ends so we know what benefit year to compare to. 
  This can happen when the current (todays) date has
  crossed into the next benefit year. 
  Example:
  Claimants benefit years are as follows:
  6/26/2022 to 6/24/2023 (present right now in FINEOS)
  6/25/2023 to 6/22/2024 (present right now in FINEOS)
  6/23/2024 to xx/xx/2025 (not yet present in FINEOS)
  Sequencing:
  The claimant submitted an extension sometime during benefit year one that spanned benefit year 1 and 2.
  Today's date becomes 6/25 and now all of a sudden benefit year 2 becomes the "current benefit year" and 
  benefit year 3 becomes the "new benefit year". Thus, the logic that determines whether a claimant's new benefit 
  year starts within 60 days would leveraging benefit year 3 instead of benefit year 2.
  */
export const getBYMoreThan60DaysInFuture = (
  claimEndDate: string,
  currentBenefitYear: BenefitYear | null,
  extensionSubmissionDate?: string
): boolean => {
  if (!currentBenefitYear) return false;

  const doesClaimEndBeforeCurrentYearEnds = dayjs(claimEndDate).isBefore(
    currentBenefitYear?.benefit_year_end_date
  );

  if (doesClaimEndBeforeCurrentYearEnds) {
    return !startsLessThanSixtyDaysInFuture(
      extensionSubmissionDate,
      currentBenefitYear?.benefit_year_start_date
    );
  }

  if (!doesClaimEndBeforeCurrentYearEnds) {
    // we do not have a next benefit year if the claim was declined for the portion after the end of the current BY,
    // so we need to check the current benefit year end date plus 1 day
    // In the example above, this is using the end date of BY2, and adding one day to approximate the start date of the (non-existant) BY3.
    const currentBYEndDatePlus1Day = dayjs(
      currentBenefitYear?.benefit_year_end_date
    )
      .add(1, "day")
      .format("YYYY-MM-DD");

    return !startsLessThanSixtyDaysInFuture(
      extensionSubmissionDate,
      currentBYEndDatePlus1Day
    );
  }
  return false;
};

// Given the previousBYEndDate, a new benefit year will start on the Sunday when the previous by ends
// and runs for 52 weeks to end on a saturday
export const computedNextBenefitYear = (
  previousBeYEndDate: string | null | undefined
): BenefitYear | null => {
  if (!previousBeYEndDate) {
    return null;
  }
  // new benefit year starts the day after the previous one ends
  const benefitYearStartDate = dayjs(previousBeYEndDate).add(1, "day");

  // benefit year runs for 52 weeks
  const benefitYearEndDate = benefitYearStartDate
    .add(52, "week")
    .subtract(1, "day");

  const benefitYear = new BenefitYear({
    benefit_year_start_date: benefitYearStartDate.format("YYYY-MM-DD"),
    benefit_year_end_date: benefitYearEndDate.format("YYYY-MM-DD"),
    current_benefit_year: false,
    invalid_benefit_years_since: null,
  });

  return benefitYear;
};

export const doesLeaveSpanBenefitYears = (
  leaveEndDate: string,
  nextBenefitYearStartDate: string | null | undefined
): boolean => {
  if (!nextBenefitYearStartDate) {
    return false;
  }
  return dayjs(leaveEndDate).isAfter(dayjs(nextBenefitYearStartDate));
};

export const nextBenefitYearData = (
  currentOrLatestBenefitYear: BenefitYear | null | undefined,
  nextBenefitYear: BenefitYear | null | undefined,
  changeRequest: ChangeRequest | null | undefined
) => {
  // if there is a next benefit year from API, use that; otherwise calculate it
  let computedOrFineosNextBY = nextBenefitYear;
  if (!nextBenefitYear?.benefit_year_start_date) {
    // New benefit year should be a continuation of the previous benefit year
    computedOrFineosNextBY = computedNextBenefitYear(
      currentOrLatestBenefitYear?.benefit_year_end_date
    );
  }

  // To check if extension dates span into a new benefit year, we need to validate
  // if there isn't already a nextBenefitYear from FINEOS.
  // If and only if, we compute the next BY and the new extension dates span into that
  // newly computed BY, then it will return true. Otherwise, returun false.
  const extensionSpansNextCalculatedBenefitYear = changeRequest?.end_date
    ? !nextBenefitYear &&
      doesLeaveSpanBenefitYears(
        changeRequest.end_date,
        computedOrFineosNextBY?.benefit_year_start_date
      )
    : false;

  const currentDate = dayjs().toString();
  const benefitYearStartsLessThanSixtyDaysInFuture =
    startsLessThanSixtyDaysInFuture(
      currentDate,
      computedOrFineosNextBY?.benefit_year_start_date
    );
  return {
    nextBYStartDate: computedOrFineosNextBY?.benefit_year_start_date,
    nextBYEndDate: computedOrFineosNextBY?.benefit_year_end_date,
    benefitYearStartsLessThanSixtyDaysInFuture,
    extensionSpansNextCalculatedBenefitYear,
  };
};

export default BenefitYear;
