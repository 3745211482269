import Alert from "src/components/core/Alert";
import React from "react";
import { useTranslation } from "src/locales/i18n";

const ApplicationPagePrepopulated = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="application-page-prepopulated">
      <Alert
        className="margin-bottom-3"
        heading={t(
          "components.universalProfileApplicationPagePrepopulated.alertTitle"
        )}
        state="info"
      >
        {t("components.universalProfileApplicationPagePrepopulated.alertBody")}
      </Alert>
    </div>
  );
};

export default ApplicationPagePrepopulated;
